import React,{useState,useEffect} from 'react'
import axios from 'axios'

function GalleryApi() {

    const [images,setImage]=useState([])

    const getImage = async ()=>{
        const res = await axios.get(`/api/showImage`)
        setImage(res.data)
    }
    useEffect(()=>{
        getImage()
    },[])
    return{
        images: [images,setImage],
        
    }
}

export default GalleryApi
