import React from 'react'
import './cbp.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Cbp() {
    return (
        <div >
             <Header/>
            <Mobilenav/>
            <div className="community-section">
                <div className="community-img">
                    <img src="./images/bishal4.jpg" alt="" />
                </div>
                <div className="community-content pt-4">
                <div className="overview">
                        <h2 className="ch">Community Based Programs</h2>
                        <p>Development programs are being operated in lead of community for exhaustive development 
                            of community. Bishal Foundation has been executing in sectors such as livelihood, poverty alleviation,
                             entrepreneurship and skill development, youth awareness and employment.  Development programs based on 
                             community supports rural economics and also establishes accessibility, representation and identity of local 
                             citizens to every programs of nation. Programs are being operated in currently existing 744 local levels 
                             in Nepal in collaboration with, Municipalities, Rural Municipalities and other partner agencies.  </p>
                    </div>
                    <div className="background">
                        <h2 className="ch">Humanitarian Aid</h2>
                        <p>Bishal Foundation has been providing aid towards natural disasters and act of god occurred in Nepal. Mostly, 
                            offering raw materials, clothes, health check-up, free medicines, free books, dresses and stationeries for children,
                             warm clothes for children in cold areas, psychological counselling including marginalized and destitute community 
                             targeted programs
                             for short term. We have been providing continuous aid to nursery and senior citizen's shelter. </p>
                    </div>
                    <div className="objectives">
                        <h2 className="ch">Skill Development Training </h2>
                        <div className="objs">
                            <p>We have a goal to bring intense economic development and create self-employment through the median of technical
                                 and commercial skill development programs. Government, International donor organization and Bank, Financial 
                                 Institutions of Nepal has linked up in this program. We are mainly operating skill and employment oriented trainings,
                                  managing initial capital to those willing to be entrepreneur, operating continuous tactical conversation to create 
                                  entrepreneurial environment and studying, researching upon market probability.  Under such performances,
                                 practical training, skilful, employment oriented trainings, miscellaneous earning trainings, leadership
                                 development training, finance literacy, fundamental training of community development are being operated for short period. </p>
                        </div>
                    </div>
                </div>
            </div>
            <GoogleMap/>
      <Footer/>
    <Copyright/> 
        </div>
    )
}

export default Cbp
