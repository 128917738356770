import React,{useState} from 'react'
import Header from '../header/Header'
import Copyright from '../pages/Copyright'
import Mobilenav from '../header/Mobilenav';
import Footer from '../pages/Footer'
import './allnews.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import JsonData from "./Newsdata.json";
import ReactPaginate from "react-paginate";


function Allnews() {
    const [newss, setNews] = useState(JsonData.slice(0, 50));
  const [pageNumber, setPageNumber] = useState(0);

  const newsPerPage = 4;
  const pagesVisited = pageNumber * newsPerPage;

  const displayNews = newss
    .slice(pagesVisited, pagesVisited + newsPerPage)
    .map((news) => {
      return (
            <div className="">
                <div className="news_show">
                    <img src={news.src} alt="" />
                    <p id="prp"><span id="hes">{news.title}</span></p>
                    <a href={news.href} id="owll" target="blank">
                        <button>
                            Read More
                        </button>
                    </a>
                </div>
            </div>
      );
    });

  const pageCount = Math.ceil(newss.length / newsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

    return (
        <div>
            <Header/>
            <Mobilenav/>
            <div className="col-md-12 pt-5 pb-5" id="all_videos">
                <div className="col-md-8 all_videoss">
                {displayNews}
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                    />
                </div>
                <div className="col-md-4 recent_news">
                    <h3><span>Recent News</span></h3>
                    <div className="rnews">
                            <a href="https://nepalpostdaily.com/englishnews/rastriya-banijya-bank-to-provide-soft-loan-to-startups/" id="owll" target="blank">
                                <p id="prp"><span id="hes">Rastriya Banijya Bank to provide soft loan to startups</span></p>
                            </a><hr/>
                            <a href="https://www.lokpath.com/story/209733" id="owll" target="blank">
                                <p id="prp"><span id="hes">विशाल फाउण्डेशनको पहलमा युवा उद्यमी प्रवर्द्धन कार्यक्रम सञ्चालन</span></p>
                            </a><hr/>
                            <a href="http://www.khabarexpresstv.com/news/722014?fbclid=IwAR3oJg2fxB2nElsra80ZioMFkAigpIGkE0hyFDRT8_dkV2xZrq1UG0VwOtg" id="owll" target="blank">
                                 <p id="prp"><span id="hes">स्टार्टअप आइडियामाथि लगानी बढाउन माग</span></p>
                            </a>
                    </div>
                </div>
            </div>
            <Footer/>
            <Copyright/>
        </div>
    )
}

export default Allnews
