

export const createEvents = (userId,token,post)=>{
    return fetch(`api/postEvent/${userId}`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        },

        body:post
    })
        .then(response=>{
            return response.json();
        })
        .catch(error=>{
            console.log(error);
        })
   
}

//delete events

export const deleteEvent = (postId, userId, token) => {
    return fetch(`api/deleteevent/${postId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

//press


export const deletePress = (postId, userId, token) => {
    return fetch(`api/deletepress/${postId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

//category


export const getCategories=()=>{
    return fetch(`api/showcategory`,{
        methos:'GET'
    })
    .then(response=>{
        return response.json();
    })
    .catch(err=>{
        console.log(err);
    })
}


//partner



//sotry


export const createstory = (userId,token,story)=>{
    return fetch(`api/postStory/${userId}`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        },

        body:story
    })
        .then(response=>{
            return response.json();
        })
        .catch(error=>{
            console.log(error);
        })
   
}


//delete story

export const deleteStory = (postId, userId, token) => {
    return fetch(`api/deletestory/${postId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


//partners

export const createPartner = (userId,token,partner)=>{
    return fetch(`api/postPartner/${userId}`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        },

        body:partner
    })
        .then(response=>{
            return response.json();
        })
        .catch(error=>{
            console.log(error);
        })
   
}


//delete partner

export const deletePartner = (postId, userId, token) => {
    return fetch(`api/deletePartner/${postId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


//gallery

export const createPhoto = (userId,token,partner)=>{
    return fetch(`api/postPhoto/${userId}`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        },

        body:partner
    })
        .then(response=>{
            return response.json();
        })
        .catch(error=>{
            console.log(error);
        })
   
}

//delete photo

export const deletePhoto = (postId, userId, token) => {
    return fetch(`api/deletephoto/${postId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


//create contact


export const createContacts = (contact)=>{
    return fetch('api/contact',{
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(contact)
    })
        .then(response=>{
            return response.json();
        })
        .catch(error=>{
            console.log(error);
        })
   
}
export const deleteContact = (postId, userId, token) => {
    return fetch(`api/deletecontact/${postId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


//press 


export const createPress = (userId,token,post)=>{
    return fetch(`api/postPress/${userId}`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            Authorization:`Bearer ${token}`
        },

        body:post
    })
        .then(response=>{
            return response.json();
        })
        .catch(error=>{
            console.log(error);
        })
   
}

