import React,{useContext} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './allevents.css'
import { Link } from 'react-router-dom';
import { GlobalState } from '../../../GlobalState';
import Header from '../../header/Header';
import Copyright from '../Copyright';
import Footer from '../Footer';
import GoogleMap from '../GoogleMap';
import Mobilenav from '../../header/Mobilenav';

function Allevents() {
    const state = useContext(GlobalState)
    const [events]=state.eventsAPI.events
    return (
        <div>
            <Header/>
            <Mobilenav/>
            <div className="allevents pt-5">
                <h1 id="le">All Events</h1>
                <div id="event-flex" >
                          {
                        events.map(event=>(
                                <div className="event-card"key={event._id}>
                                    <img src={'/'+event.image} className="card-img-top" alt="..."/>
                                    <div className="card-body">
                                        <h5 className="card-title titles" style={{textAlign:'center'}}>{event.title}</h5>
                                        <div id="df">
                                            <p className="bbf">By: Bishal Foundation</p>
                                            <Link to={`/eventdetails/${event._id}`} id="linkss" >
                                                <button className="event-button" >
                                                    read more
                                                </button>
                                            </Link>                                         
                                        </div>
                                    </div>
                                </div>   
                        ))
                    } 
                </div>
            </div>
            <GoogleMap/>
            <Footer/>
            <Copyright/>
        </div>
    )
}

export default Allevents

