import React from 'react';
import ReactDOM from 'react-dom';
import { DataProvider } from './GlobalState';
import './index.css';

import Routes from './Routes';



ReactDOM.render(
  <React.StrictMode>
    <DataProvider>   
     <Routes/>
    </DataProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


