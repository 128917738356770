import React from 'react'
import './volunteer.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Pvolunteer() {
    return (
        <div>
            <Header/>
            <Mobilenav/>
            <div className="vols">
                <p>Past Bishal Foundation Volunteers</p>
            </div>
            <div className="volunteer">
                <div className="nvol">
                    <p>Number of Past Volunteers: 15</p>
                    <hr />
                </div>
                <div className="volunteers">
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/bfoundationlogo.svg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            <p className="name"><span>Name:</span> Saphal Subedi</p>
                            <p className="address"><span>Address:</span> Bagbazar</p>
                            <p className="bloodg"><span>Blood Group:</span> O</p>
                            <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p>
                        </div>
                    </div>
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/bfoundationlogo.svg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            <p className="name"><span>Name:</span> Saphal Subedi</p>
                            <p className="address"><span>Address:</span> Bagbazar</p>
                            <p className="bloodg"><span>Blood Group:</span> O</p>
                            <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p>
                        </div>
                    </div>
                    
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/bfoundationlogo.svg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            <p className="name"><span>Name:</span> Saphal Subedi</p>
                            <p className="address"><span>Address:</span> Bagbazar</p>
                            <p className="bloodg"><span>Blood Group:</span> O</p>
                            <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p>
                        </div>
                    </div>
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/bfoundationlogo.svg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            <p className="name"><span>Name:</span> Saphal Subedi</p>
                            <p className="address"><span>Address:</span> Bagbazar</p>
                            <p className="bloodg"><span>Blood Group:</span> O</p>
                            <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p>
                        </div>
                    </div>
                </div>
            </div>
            <GoogleMap/>
      <Footer/>
    <Copyright/> 
        </div>
    )
}

export default Pvolunteer
