import React,{useState,useEffect} from 'react'
import axios from 'axios'

function PartnerAPI() {
    const [partners,setPartner]=useState([])
    const [callback,setCallback] = useState(false)
    const [category,setCategory] = useState('')
    
    const [result ,setResult] = useState(0)


    const getPartner = async ()=>{
        const res = await axios.get(`/api/showPartner?${category}`)

        setPartner(res.data.partners)
        setResult(res.data.result)
    }
    useEffect(()=>{
        getPartner()
    },[callback,category])
    return{
        partners: [partners,setPartner],
        callback: [callback,setCallback],
        category: [category,setCategory],
        result: [result ,setResult]
    }
}

export default PartnerAPI
