import React from 'react'
import Header from '../header/Header'
import Copyright from '../pages/Copyright'
import Footer from '../pages/Footer'
import Mobilenav from '../header/Mobilenav';
import './allvideos.css'

function Allvideos() {
    return (
        <div>
            <Header/>
            <Mobilenav/>
            <div className="col-md-12 pt-5 pb-5" id="all_videos">
                <div className="col-md-8 col-sm-12 all_videoss">
                   <div className="our_videos">
                      <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fbishalfoundation%2Fvideos%2F1035302236985727%2F&show_text=false&width=560&t=0"
                      width="320" height="185" scrolling="no" frameborder="0" allowfullscreen="true" 
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                      <p>काेराेना भाइरस Covid-19 सँग डराएर हाेइन उच्च सावधानी अपनाएर अघि बढाैँ ।</p>
                  </div>
                  <div className="our_videos">
                  <iframe width="320" height="185" src="https://www.youtube.com/embed/Q2rocS7kdto"
                   title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
                  encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      <p>Mr. Naresh Prasad Shrestha in the Inauguration Ceremony of Startup Idea Call Program 2077.</p>
                  </div>
                  <div className="our_videos">
                  <iframe width="320" height="185" src="https://www.youtube.com/embed/zlPXJgaWxoo" 
                  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay;
                   clipboard-write;
                   encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <p>Mr. Bishal Gaire in the Inauguration Ceremony of Startup Idea Call Program 2077.</p>
                  </div> 
                  <div className="our_videos">
                  <iframe width="320" height="185" src="https://www.youtube.com/embed/zlPXJgaWxoo" 
                  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay;
                   clipboard-write;
                   encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <p>Mr. Bishal Gaire in the Inauguration Ceremony of Startup Idea Call Program 2077.</p>
                  </div> 
                </div>
                <div className="col-md-4 recent_news">
                    <h3><span>Recent News</span></h3>
                    <div className="rnews">
                                <a href="https://nepalpostdaily.com/englishnews/rastriya-banijya-bank-to-provide-soft-loan-to-startups/" id="owll" target="blank">
                                    <p id="prp"><span id="hes">Rastriya Banijya Bank to provide soft loan to startups</span></p>
                                </a>
                                <hr />
                            <a href="https://www.lokpath.com/story/209733" id="owll" target="blank">
                                <p id="prp"><span id="hes">विशाल फाउण्डेशनको पहलमा युवा उद्यमी प्रवर्द्धन कार्यक्रम सञ्चालन</span></p>
                            </a><hr />
                            <a href="http://www.khabarexpresstv.com/news/722014?fbclid=IwAR3oJg2fxB2nElsra80ZioMFkAigpIGkE0hyFDRT8_dkV2xZrq1UG0VwOtg" id="owll" target="blank">
                                 <p id="prp"><span id="hes">स्टार्टअप आइडियामाथि लगानी बढाउन माग</span></p>
                            </a>
                    </div>
                </div>
            </div>
            <Footer/>
            <Copyright/>
        </div>
    )
}

export default Allvideos
