import React from 'react'

import {BrowserRouter as Router,Switch,Route} from 'react-router-dom'
import App from './App'
import Addphoto from './components/admin/adminaction/addphoto/Addphoto'
import AdminRoute from './components/admin/adminroute/Adminroute'
import Admindashboard from './components/admin/dashboard/Admindashboard'
import Acontact from './components/admin/dashboard/dash/Acontact'
import Contact from './components/admin/dashboard/dash/Contacts'
import Event from './components/admin/dashboard/dash/Event'

import Partner from './components/admin/dashboard/dash/Partner'
import Presss from './components/admin/dashboard/dash/Presss'
import Story from './components/admin/dashboard/dash/Story'
import Login from './components/admin/Login'
import Aboutus from './components/innerpage/Aboutus'
import Accountandt from './components/innerpage/Accountandt'
import Advb from './components/innerpage/Advb'
import Advisory from './components/innerpage/Advisory'
import Allnews from './components/innerpage/Allnews'
import Allpartner from './components/innerpage/Allpartner'
import Allvideos from './components/innerpage/Allvideos'
import Awareness from './components/innerpage/Awareness'
import Career from './components/innerpage/Career'
import Cbp from './components/innerpage/Cbp'
import Donate from './components/innerpage/Donate'
import Faq from './components/innerpage/Faq'
import Humanaid from './components/innerpage/Humanaid'
import Internship from './components/innerpage/Internship'
import Pressrelease from './components/innerpage/Pressrelease'
import Pvolunteer from './components/innerpage/Pvolunteer'
import Researchdialogue from './components/innerpage/Researchdialogue'
import Sdgs from './components/innerpage/Sdgs'
import Sec from './components/innerpage/Sec'
import Skill from './components/innerpage/Skill'
import Startupida from './components/innerpage/Startupida'
import Volunteer from './components/innerpage/Volunteer'
import Allevents from './components/pages/allevents/Allevents'
import Eventdetail from './components/pages/eventdeail/Eventdetail'
import NotFound from './components/utils/notfound/NotFound'
import Home from './Home'


function Routes() {
    return (
        <div>
            
            <Router>
                <Switch>
                <Route exact path="/" component={App}/>
                <Route exact path="/home" component={Home}/>
                <Route exact path="/sdgs" component={Sdgs}/>
                <Route exact path="/executiveboard" component={Advb}/>
                <Route exact path="/faq" component={Faq}/>
                <Route exact path="/aboutus" component={Aboutus}/>
                <Route exact path="/communitybasedprogram" component={Cbp}/>
                <Route exact path="/accountandtransparency" component={Accountandt}/>
                <Route exact path="/humanitarianaid" component={Humanaid}/>
                <Route exact path="/skilltraining" component={Skill}/>
                <Route exact path="/awareness" component={Awareness}/>
                <Route exact path="/startupideacallandprogram" component={Startupida}/>
                <Route exact path="/researchandpolicydialogue" component={Researchdialogue}/>
                <Route exact path="/pressrelease" component={Pressrelease}/>
                <Route exact path="/career" component={Career}/>
                <Route exact path="/internshipprogram" component={Internship}/>
                <Route exact path="/contact" component={Contact}/>
                <Route exact path="/all-events" component={Allevents}/>
                <Route exact path='/eventdetails/:id' component={Eventdetail} />
                <Route exact path="/login" component={Login}/>

                <Route exact path="/avisorboard" component={Advisory}/>
                <Route exact path="/secreatariat" component={Sec}/>
                <Route exact path="/allvideos" component={Allvideos}/>
                <Route exact path="/allnews" component={Allnews}/>
                <Route exact path="/allpartners" component={Allpartner}/>
                <Route exact path="/volunteers" component={Volunteer}/>
                <Route exact path="/presentvolunteers" component={Pvolunteer}/>

                <Route exact path="/donate" component={Donate}/>

                {/* <Route exact path="*" component={NotFound}/>  */}
                {/* <AdminRoute exact path="*" component={NotFound}/> */}
                <AdminRoute exact path='/admin/dashboard' component={Admindashboard} /> 
                
                <AdminRoute exact path='/addphoto' component={Addphoto} />
                <AdminRoute exact path='/event' component={Event} /> 
                <AdminRoute exact path='/story' component={Story} /> 
                <AdminRoute exact path='/partner' component={Partner} />
                <AdminRoute exact path='/addpressrelease' component={Presss} /> 
                <AdminRoute exact path='/contactdetails' component={Acontact} /> 
                
                    
                {/* <AdminRoute exact path='/adminaction' component={AdminAction} /> */}
                
                </Switch>
            </Router>
       
        </div>
    )
}

export default Routes