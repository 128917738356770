import React from 'react'
import './accountandt.css';
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';
function Accountandt() {
    return (
        <div>
             <Header/>
            <Mobilenav/>
        <div className="transnaccount pt-5">
        <h2 className="taa pb-4"> Transparency and accountability  </h2>
        <div className="transimgcont">
          <div className="transdesc">
            <p>
            Bishal foundation has been executing transparently and responsibly being completely 
            loyal towards targeted and partner agencies. Based on the regulations and guidelines set
             by the Board of Directors, the work has been accomplished with high moral basis and responsibility 
             completely abiding the right to information avoiding the inconsistence of selfishness, accessing everyone's 
             dignity equally in timely manner in full compliance with Procurement Act whether at the time of procuring service 
             or offering the service. This foundation has managed to keep its every subject in website in order to establish 
             strong and smooth relationship between customer and partner agencies and in the other hand, it is very responsive 
             towards the questioners. It has been working in very responsive manner towards government, private sector, 
             targeted area, partner agencies and board of directors of the foundation. The Foundation has earned an excellent
              reputation through the 
            following medians keeping the entire welfare of the organization in centre. 
            </p>
            <ol type="1">
              <li>Public hearing (Social audit)</li>
              <li>Organizational audit</li>
              <li>Establishment of relevancy and access in information and service flow</li>
              <li>Legal decision and implementation </li>
              <li>Timely and accurate</li>
              <li>Standard setting </li>
              <li>Investigation</li>
              <li>8.	Answerability </li>
            </ol>
          </div> 
          <div className="transimg">
            <img src="./images/aac.jpg" />
          </div>
        </div>
      </div>
      <GoogleMap/>
      <Footer/>
    <Copyright/>
        </div>
    )
}

export default Accountandt
