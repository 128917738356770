import React from 'react'
import './health.css'
import './humanaid.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Humanaid() {
    return (
        <div >
             <Header/>
            <Mobilenav/>
            <div className="sdgss">
        <div className="art">
          <div className="col-md-12 contentss">
            <div className=" dfc">
              <p className="sspw">Humanitarian Aid</p>
              <p className="textss">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
               
              </p>
            </div>
            <div className="image">
              <img src="./images/human1.jpg" alt="" />
            </div>
          </div>
        </div>
        <div className="gallerys">
            <p><span>Some Photos Of Humanitarian Aid</span></p>
            <div className="imagess">
              <div className="img1">
                  <a href=""><img src="./images/human2.jpg" alt="" /></a>
              </div>
              <div className="img1">
                  <a href=""><img src="./images/human3.jpg" alt="" /></a>
              </div>
              <div className="img1">
                  <a href=""><img src="./images/human4.jpg" alt="" /></a>
              </div>
            </div>
        </div>
      </div>
      <GoogleMap/>
      <Footer/>
    <Copyright/> 
        </div>
        
    )
}

export default Humanaid
