import React from 'react'

function GoogleMap() {
    return (
        <div className="pt-5">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26701.331377580842!2d85.31279967677143!3d27.697405794434673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb196993673d21%3A0xe06fcae0bbb505b4!2sBishal%20Foundation!5e0!3m2!1sen!2snp!4v1629195941251!5m2!1sen!2snp"
             width="100%" height="450" style={{ border: 0 }}  allowfullscreen="" loading="lazy"></iframe>

        </div>
    )
}

export default GoogleMap
