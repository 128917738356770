import React,{Component} from 'react'
import './Footer.css'
import { FacebookProvider, Page } from 'react-facebook';
import { Link } from 'react-router-dom';
import logo from '../../image/bishallogo.png'
function Footer() {
    return (
        <div className="footer">
            <div className="footss">
            <div className="footer-container1 pt-4 pb-2">
                    <div className="footer-div1 ">
                        <img src={logo} style={{height:'55px',width:'135px'}} className="mb-4"/>
                        <p> विशाल फाउण्डेशन नेपालको विकास र समृद्धीमा योगदान गर्न चाहने उद्यमीहरुको च्यारिटेवल संस्था हो । नेपाल अधिराज्यभर कार्यक्षेत्र रहेको यस 
                            फाउण्डेशनमार्फत दिगो विकासका लक्ष्य, मानविय सहायता, सीप विकास तालीम, सचेतनामुलक कार्यक्रम र सामुदायिक विकासमा आधारित कार्यक्रमहरु 
                             सञ्चालित छन् । फाउण्डेशनमा जात, धर्म, लिंग, बर्ण, राजनीतिक आस्थाका आधारमा कसैलाई पनि बिभेद गरिने छैन । बि.स २०७५ साल बैशाख १९
                              गते मुनाफा बितरण नगर्ने कम्पनीकारुपमा रुपमा कम्पनी रजिष्टार्ड कार्यालयमा दर्ता भएको यो फाउण्डेशनले मिति २०७८ असार मसान्तसम्म सरकार, 
                            निजि क्षेत्र र अन्तराष्ट्रिय साझेदार निकासहित ५७  साझेदारसंगको सहकार्यमा २१ वटा परियोजनाहरु सफलतापुर्वक सम्पन्न गरेको छ । </p>
                    </div>

                    <div className="footer-div2 ">
                            <FacebookProvider appId="100202588229539" > 
                                
                                <Page href="https://www.facebook.com/bishalfoundation" tabs="timeline" height="300px" />
                            </FacebookProvider>  
                    </div>
            </div>
            <div className="footer-container2 pt-4 pb-2"> 
                    <div className="footer-div3 ">
                        <h3>Contact us</h3>
                        <ul className="footerul">
                            <li>Anamnagar,Kathmandu,Nepal</li>
                            <li> <i class="fas fa-phone-alt"></i> 977-01-5705651/52</li>
                            {/* <li><i class="fas fa-mail-bulk"></i>Fax: 977-1-444/445</li> */}
                            <li><i class="far fa-envelope"></i>info@bishalfoundation.org <br />
                            bishalfoundation2019@gmail.com</li>
                            
                            <div className="  fus">
                                <a href="https://www.facebook.com/bishalfoundation" target="_blank" ><i class="fab fa-facebook"></i></a>
                                <a href="https://www.instagram.com/bishalfoundation/" target="_blank"><i className="fab fa-instagram ml-3" /></a>
                                <a href="https://www.youtube.com/channel/UC8WT9gNWmD7Y6Ou9mUKETMQ" target="_blank" ><i class="fab fa-youtube"></i></a>
                                <a to="" className="wa" >
                                <div class="white">
                                    <div className="div">
                                        <i class="fas fa-phone"></i>
                                    </div>
                                </div></a>
                            </div> 
                        </ul>
                    </div>  
            </div>
            </div>
        </div>
      )
}

export default Footer
