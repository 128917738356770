import React from 'react'

function NotFound() {
    return (
        <center>
            <div className="mt-5 pt-5">
            404 | Not Found
        </div>
        </center>
    )
}

export default NotFound
