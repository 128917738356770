
import React,{useContext,useState,useEffect} from 'react'
import {useParams} from 'react-router-dom'
import { GlobalState } from '../../../GlobalState'
import Header from '../../header/Header'
import Mobilenav from '../../header/Mobilenav'
import Copyright from '../Copyright'
import Footer from '../Footer'

import './eventdetail.css'

function Eventdetail() {
    const params = useParams()
    const state = useContext(GlobalState)
    const [events]=state.eventsAPI.events
    const [eventDetail,setEventDetail] = useState([])

    
    useEffect(()=>{
        if(params.id){
            events.forEach(event=>{
                if(event._id === params.id) setEventDetail(event)
            })
        }
    },[params.id,events])
    
    if(eventDetail.length===0)return null;
      
    return (
        <>
        <Header/>
        <Mobilenav/>
        <div className="detail container pb-5">
           <div className="container mt ">
                <div className="row mt-2 ims">
                       
                    <div id="tvd" className="pt-5 pb-4">
                            <div className="col-md-4">
                                <span className="tit"> {eventDetail.title}</span><br/>
                                <span id="span">{eventDetail.venue}</span>
                            </div>
                            <div className="col-md-5"></div>
                            <p id="date"><span>Date:</span> {eventDetail.date}</p>
                    </div>
                    <img src={"/"+eventDetail.image} alt="" />
                </div>
            </div>
            <div className="pd ">
                <h3 id="le" className="ae">About Events</h3>
                <p className="desc">{eventDetail.description}</p>
            </div>
        </div>
        <Copyright/>
        </>
    )
}

export default Eventdetail
