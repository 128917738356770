import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './modelp.css'

function Modelp() {
    return (
      <div className="mnotification">
        <div className="modal fade" id="exampleModalp" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body" id="notification">
                <i class="far fa-bell"></i> <p>We'd like to show you nofifications for the latest news and updates.</p>
              </div>
              <div className="modal-footer" id="notf">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id="cab">Cancel</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" id="cab">Allow</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Modelp
