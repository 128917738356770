import logo from '../../image/bishallogo.png'
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './header.css'
import Navone from './Navone';

import { Link } from 'react-router-dom';

import { withRouter } from 'react-router-dom';

function Header({history}) {   
        
    return (
        <>
         <Navone/>
        <div  style={{position:'sticky',top:'-1px',zIndex:'2'}}> 
           
            <nav className="navbar navbar-expand-lg navbar-light dno" >
                <div className="container-fluid">
                    <Link to="/home" className="navbar-brand nbs" href="#" id="lik" ><img src={logo} alt="" height="55px" width="135px" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse whit" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 uls " >
                        <li id="block">Who We Are
                                <div id="cate" className="pt">
                                    <ul id="pad" className="pad">
                                        <li id="men" className="actives"><Link to="/aboutus" id="li">About Us</Link>
                                            <div id="sec" className="sec">
                                                <ul style={{paddingTop:'19px'}}>
                                                    <h3 id="abbs" style={{textAlign:'left',paddingTop:'30px'}}>About Us</h3>
                                                    <div style={{display:'flex'}}>
                                                        <p>Bishal Foundation is charitable organization of entrepreneurs devoted in sustainable development.
                                                             Programs based on goal of sustainable development,
                                                             humanitarian aid, skill development training, public awareness programs and community development
                                                              are being performed through this foundation.
                                                        </p>
                                                        <div style={{marginLeft:'35px',marginRight:'10px'}}>
                                                            <img src="images/who1.jpg" alt="" height="261px" width="325px" />
                                                        </div>
                                                </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <span></span>
                                        <li  id="men2"><Link to="/executiveboard" id="li">Executive Board</Link>
                                            <div id="sec2" >
                                                <ul style={{marginTop:'59px',paddingTop:'13.5px'}}>
                                                    <h3 id="ebd" style={{textAlign:'left'}}>Our Executive</h3>
                                                    <div style={{display:'flex'}}>
                                                        <p> Bishal Foundation is charitable organization of entrepreneurs devoted in sustainable development.
                                                             Programs based on goal of sustainable development,
                                                             humanitarian aid, skill development training, public awareness programs and community development
                                                              are being performed through this foundation.
                                                        </p>
                                                        <div style={{marginLeft:'35px',marginRight:'10px'}}>
                                                            <img src="images/meetf.jpg" alt="" height="261px" width="325px" />
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men3"><Link to="/avisorboard" id="li">Advisory Board</Link>
                                            <div id="sec3">
                                                <ul style={{marginTop:'59px',paddingTop:'13.5px'}}>
                                                    <h3 id="avb" style={{textAlign:'left'}}>Adisory Board</h3>
                                                    <div style={{display:'flex'}}>
                                                        <p> Bishal Foundation is charitable organization of entrepreneurs devoted in sustainable development.
                                                             Programs based on goal of sustainable development,
                                                             humanitarian aid, skill development training, public awareness programs and community development
                                                              are being performed through this foundation.
                                                        </p>
                                                        <div style={{marginLeft:'35px',marginRight:'10px'}}>
                                                            <img src="images/adivsoryb.jpg" alt="" height="261px" width="325px" />
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men4"><Link to="/secreatariat" id="li">Secreatariat</Link>
                                            <div id="sec4" >
                                                <ul  style={{marginTop:'59px',paddingTop:'13.5px'}}>
                                                    <h3 id="fss" style={{textAlign:'left'}}>Secreatariat</h3>
                                                    <div style={{display:'flex'}}>
                                                        <p> Bishal Foundation is charitable organization of entrepreneurs devoted in sustainable development.
                                                             Programs based on goal of sustainable development,
                                                             humanitarian aid, skill development training, public awareness programs and community development
                                                              are being performed through this foundation.
                                                        </p>
                                                        <div style={{marginLeft:'35px',marginRight:'10px'}}>
                                                            <img src="images/executives.jpg" alt="" height="261px" width="325px" />
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men5"><Link to="/accountandtransparency" id="li">Transparency <span style={{paddingRight:'40px'}}>&</span> Accountability</Link>
                                            <div id="sec5" >
                                                <ul  style={{marginTop:'59px',paddingTop:'13.5px'}}>
                                                    <h3 id="taa"style={{textAlign:'left'}}>Transparency & Accountability</h3>
                                                    <div style={{display:'flex'}}>
                                                        <p>Bishal foundation has been executing transparently and responsibly being completely loyal towards targeted and partner agencies.
                                                             Based on the regulations and guidelines set by the Board of Directors, the work has been accomplished with high moral basis and
                                                              responsibility completely abiding the right to information ...
                                                        </p>
                                                        <div style={{marginLeft:'35px',marginRight:'10px'}}>
                                                            <img src="images/aat.jpg" alt="" height="261px" width="325px" />
                                                        </div>
                                                    </div>
                                                </ul> 
                                            </div>
                                        </li>
                                        <li  id="men6"><Link to="/faq" id="li">FAQ</Link>
                                            <div id="sec6" >
                                                <ul style={{marginTop:'70px',paddingTop:'13.5px'}}>
                                                    <h3 id="faq"style={{textAlign:'left'}}>FAQ</h3>
                                                    <div style={{display:'flex'}}>
                                                        <p><strong>Q. What is Bishal Foundation? </strong><br />
                                                            The Bishal Foundation is a non-profit organization working for the prosperity of Nepal. It is a charitable organization of 
                                                            entrepreneurs who stepped forward intending to 
                                                            contribute to the development and prosperity of Nepal through social activities....
                                                        </p>
                                                        <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                            <img src="images/faq.jpg" alt="" height="261px" width="325px" />
                                                        </div>
                                                    </div>
                                                </ul> 
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <span className="non" style={{paddingLeft:'20px',paddingRight:'20px'}}></span>
                            <li id="blocks" className="wwd">What We Do
                                <div id="wwd" className="wws" >
                                    <ul >
                                        <li className="sdgs" id="li"><Link to="/sdgs" id="sdgs"style={{textDecoration:'none',color:'black'}}>SDGS(Sustainable Development Goals)</Link>
                                        </li>
                                        <li className="sdgs1" ><Link to="/communitybasedprogram" id="li">Community Based Programs</Link>
                                            <div id="dns1">
                                                 <ul className="dns">
                                                    <li><Link to="/communitybasedprogram" id="li">Humanitarian Aid</Link></li>
                                                   <li><Link to="/communitybasedprogram" id="li">Skill Training</Link></li>
                                                   <li id="trsm"><Link to="/awareness" id="li">Awareness Program</Link></li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  ><Link to="/startupideacallandprogram" id="li">Startup Idea Call Program</Link></li>
                                        <li id="trsm" ><Link to="/researchandpolicydialogue" id="li">Research and Policy Dialogue</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <span className="non" style={{paddingLeft:'20px',paddingRight:'20px'}}></span>
                            <li id="blocka" className="ba"> Resource and Media
                                <div id="catea" className="pt1">
                                    <ul id="pada"  className="pad">
                                        <li id="mena " className="actives acta"><Link to="/pressrelease" id="li">Press Release</Link>
                                            <div id="seca">
                                                <ul  id="pta" className="secs" >
                                                    <h3 id="pta1">Press Release</h3>
                                                <div style={{display:'flex'}}>
                                                        <p>Bishal Media group is the national level media company under Bishal Organizations advocating for the national prosperity.
                                                             It has been promoting the Nepalese economic, 
                                                            social and political changes through varieties of medias like television, radio, newspaper and online portal
                                                            For the achievement of sustainable development, ...
                                                        </p>
                                                        <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                            <img src="images/pressrealese.jpg" alt="" height="261px" width="325px" />
                                                        </div>
                                                </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men1a"><Link  id="li">Successful Stories</Link>
                                            <div id="sec1a">
                                                <ul  id="pta" className="secs">
                                                    <h3 id="pta2">Successful Stories</h3>
                                                    <div style={{display:'flex'}}>
                                                            {/* <p> First Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro,
                                                                magnam distinctio? Sequi eaque, dicta nihil corrupti ipsum exercitationem tenetur iure asperiores
                                                                itaque distinctio nesciunt nisi sapiente temporibus nobis minus magnam!
                                                            </p> */}
                                                            <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                            <img src="images/successfull.jpg" alt="" height="261px" width="325px" />
                                                            </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men2a"><Link to="/allnews" id="li">News Coverage</Link>
                                            <div id="sec2a">
                                                <ul  id="pta" className="secs">
                                                    <h3 id="pta3">News Coverage</h3>
                                                    <div style={{display:'flex'}}>
                                                        {/* <p> Second Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro,
                                                            magnam distinctio? Sequi eaque, dicta nihil corrupti ipsum exercitationem tenetur iure asperiores
                                                            itaque distinctio nesciunt nisi sapiente temporibus nobis minus magnam!
                                                        </p> */}
                                                        <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                            <img src="images/newscoverage.jpg" alt="" height="261px" width="325px" />
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men3a"><Link to="/allvideos" id="li">Speech/Videos</Link>
                                            <div id="sec3a">
                                                <ul  id="pta" className="secs">
                                                    <h3 id="pta4">Speech/Videos</h3>
                                                    <div style={{display:'flex'}}>
                                                        {/* <p> Third Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro,
                                                            magnam distinctio? Sequi eaque, dicta nihil corrupti ipsum exercitationem tenetur iure asperiores
                                                            itaque distinctio nesciunt nisi sapiente temporibus nobis minus magnam!
                                                        </p> */}
                                                        <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                            <img src="images/speech.jpg" alt="" height="261px" width="325px"/>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men4a"><Link to="/allvideos" id="li">Gallery</Link>
                                            <div id="sec4a">
                                                <ul  id="pta" className="secs">
                                                    <h3 id="pta5">Gallery</h3>
                                                    <div style={{display:'flex'}}>
                                                        {/* <p> Third Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro,
                                                            magnam distinctio? Sequi eaque, dicta nihil corrupti ipsum exercitationem tenetur iure asperiores
                                                            itaque distinctio nesciunt nisi sapiente temporibus nobis minus magnam!
                                                        </p> */}
                                                        <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                            <img src="images/ogallery.jpg" alt="" height="261px" width="325px"/>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men5a"><Link id="li">Annual Reports</Link>
                                            <div id="sec5a">
                                                <ul  id="pta" className="secs">
                                                    <h3 id="pta6">Annual Reports</h3>
                                                    <div style={{display:'flex'}}>
                                                        {/* <p> Second Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro,
                                                            magnam distinctio? Sequi eaque, dicta nihil corrupti ipsum exercitationem tenetur iure asperiores
                                                            itaque distinctio nesciunt nisi sapiente temporibus nobis minus magnam!
                                                        </p> */}
                                                        <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                         <img src="images/annualreport.jpg" alt="" height="261px" width="325px" />
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </li>
                            <span className="non" style={{paddingLeft:'20px',paddingRight:'20px'}} id="sp"></span>
                            <li id="blockp" >Get Involved
                                <div id="catep" className="pts">
                                    <ul id="padp" className="pad padp" >
                                        <li id="men " className="actives"><Link to="/internshipprogram" id="li">Internship</Link>
                                            <div id="secp" >
                                                <ul  id="ptp" className="sec0">
                                                    <h3 id="itrs">Internship</h3>
                                                <div style={{display:'flex'}}>
                                                        {/* <p>Zero Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro,
                                                            magnam distinctio? Sequi eaque, dicta nihil corrupti ipsum exercitationem tenetur iure asperiores
                                                            itaque distinctio nesciunt nisi sapiente temporibus nobis minus magnam!
                                                        </p> */}
                                                        <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                            <img src="images/internship.jpg" alt="" height="261px" width="325px" />
                                                        </div>
                                                </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men1p"><Link to="/presentvolunteers" id="li">Volunteering</Link>
                                            <div id="sec1p">
                                                <ul  id="ptp" >
                                                    <h3 id="vtr">Volunteering</h3>
                                                    <div style={{display:'flex'}}>
                                                            {/* <p> First Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro,
                                                                magnam distinctio? Sequi eaque, dicta nihil corrupti ipsum exercitationem tenetur iure asperiores
                                                                itaque distinctio nesciunt nisi sapiente temporibus nobis minus magnam!
                                                            </p> */}
                                                            <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                                <img src="images/volunteer1.jpg" alt="" height="261px" width="325px" />
                                                            </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men2p"><Link to="/allpartners" id="li">Partnership</Link>
                                            <div id="sec2p">
                                                <ul  id="ptp">
                                                    <h3 id="prts">Partnership</h3>
                                                    <div style={{display:'flex'}}>
                                                        {/* <p> Second Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro,
                                                            magnam distinctio? Sequi eaque, dicta nihil corrupti ipsum exercitationem tenetur iure asperiores
                                                            itaque distinctio nesciunt nisi sapiente temporibus nobis minus magnam!
                                                        </p> */}
                                                        <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                            <img src="images/partnership.jpg" alt="" height="261px" width="325px"/>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men3p"><Link to="/career" id="li">Carrer</Link>
                                            <div id="sec3p">
                                                <ul  id="ptp">
                                                    <h3 id="crr">Career</h3>
                                                    <div style={{display:'flex'}}>
                                                        {/* <p> Third Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro,
                                                            magnam distinctio? Sequi eaque, dicta nihil corrupti ipsum exercitationem tenetur iure asperiores
                                                            itaque distinctio nesciunt nisi sapiente temporibus nobis minus magnam!
                                                        </p> */}
                                                        <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                            <img src="images/career.jpg" alt=""height="261px" width="325px"/>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                        <li  id="men3p"><Link to="/donate" id="li">Donate</Link>
                                            <div id="sec3p">
                                                <ul  id="ptp" style={{marginTop:'-46px'}}>
                                                    <h3 id="crr">Donate</h3>
                                                    <div style={{display:'flex'}}>
                                                        {/* <p> Third Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro,
                                                            magnam distinctio? Sequi eaque, dicta nihil corrupti ipsum exercitationem tenetur iure asperiores
                                                            itaque distinctio nesciunt nisi sapiente temporibus nobis minus magnam!
                                                        </p> */}
                                                        <div style={{marginLeft:'35px',marginRight:'10px',marginBottom:'10px'}}>
                                                            <img src="images/donate.jpg" alt=""height="261px" width="325px"/>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <span className="non" style={{paddingLeft:'105px',paddingRight:'0px'}}></span>
                            <li id="blockh"><i class="fas fa-search"></i>
                                <div id="cateh">
                                    <ul id="padh">
                                        <div className="ss">
                                            <form action="">
                                                <input type="text"  placeholder="search....."/>
                                                <button><i class="fas fa-search"></i></button>
                                            </form>
                                        </div>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        </>
    )
}

export default withRouter(Header)
