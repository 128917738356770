import React from 'react'
import './admindashboard.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { isAuthenticated, signout } from '../auth';
import { Fragment } from 'react';

function Admindashboard({history}) {
    return (
        <div>
            <div className="dash">
                <div className="dashside1">
                    <h2>Bishal Foundation</h2>
                    <p className="dashp"><Link to='/admin/dashboard' id="alink"><i class="fas fa-tachometer-alt"></i> Dashboard</Link></p>
                    <div className="dash-list">
                        <li><Link to='/event' id="alink">Events</Link></li>
                        <li><Link  to='/story' id="alink">Stories</Link></li>
                        <li><Link to='/partner' id="alink">Partners</Link></li>
                        <li><Link to='/addpressrelease' id="alink">Press Release</Link> </li>
                        <li><Link to='/contactdetails'id="alink">Contacts</Link></li>
                        <li>Internship</li>
                        <li>Volunteering</li>
                    </div>
                </div>
                <div className="dashside2">
                    <div className="dashhead">
                        <div className="dashright">
                            <div className="img">   
                            </div>
                            {isAuthenticated()&&(
                            <Fragment>
                            <div className="dropdown">
                                <p className="dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                    Admin
                                </p>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <li><i class="fas fa-user"></i> Profile</li>
                                    <li><i class="fas fa-cog"></i> Setting</li>  
                                              <li onClick={()=>signout(()=>{
                                                    history.push('/')
                                                })}>
                                                
                                                <Link to='/' id="alin" ><i class="fas fa-sign-out-alt"></i> Logout</Link>
                                            
                                              </li>
                                </ul>
                            </div>
                            </Fragment>
                            )}
                        </div>
                    </div>
                    <div className="allthings">
                        <div className="event-block">
                                <p className="tenp">10<sup>+</sup>
                                <br /><span>Events</span></p>
                                <div className="icon">
                                    <p><i class="fas fa-calendar-alt"></i></p>
                                </div>
                        </div>
                        <div className="event-block">
                                <p className="tenp">7<sup>+</sup>
                                <br /><span>Stories</span></p>
                                <div className="icon">
                                    <p><i class="fas fa-book"></i></p>
                                </div>
                        </div>
                        <div className="event-block">
                                <p className="tenp">20<sup>+</sup>
                                <br /><span>Partners</span></p>
                                <div className="icon">
                                    <p><i class="fas fa-users"></i></p>
                                </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admindashboard
