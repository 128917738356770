
import React from "react";
import './gallery.css'
import {Link} from 'react-router-dom'


function Ourgallery() {


    return (
        <div style={{width:'90%',margin:'auto'}} id="ourgallery" className="pt-4">
            <h1 className="initial-heading" id="le" style={{zIndex:'2'}}>Videos Updates</h1>
           <div style={{marginTop:"-5px"}}>
             <div className="updated_video pt-3">
                   <div className="our_videos">
                   <iframe width="350" height="200" src="https://www.youtube.com/embed/8Mm9vvh3P4E" title="YouTube video player" frameborder="0" 
                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      <p>Startup Idea Call Program !</p>
                  </div>
                  <div className="our_videos">
                  <iframe width="350" height="200" src="https://www.youtube.com/embed/Q2rocS7kdto"
                   title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
                  encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      <p>Mr. Naresh Prasad Shrestha in the Inauguration Ceremony of Startup Idea Call Program 2077.</p>
                  </div>
                  <div className="our_videos">
                  <iframe width="350" height="200" src="https://www.youtube.com/embed/zlPXJgaWxoo" 
                  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay;
                   clipboard-write;
                   encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <p>Mr. Bishal Gaire in the Inauguration Ceremony of Startup Idea Call Program 2077.</p>
                  </div> 
             </div>
             <div className="video_more_button">
               <Link to="/allvideos"><button>View All</button></Link>
             </div>
           </div>
        </div>
    )
}

export default Ourgallery
