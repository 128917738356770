import React from 'react'
import './health.css'
import './awarness.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Awareness() {
    return (
        <div >
             <Header/>
            <Mobilenav/>
            <div className="sdgss">
        <div className="art">
          <div className="col-md-12 contentss">
            <div className=" dfc">
              <p className="sssspw">Awareness Program</p>
              <p className="textss">Miscellaneous creative and awareness programs are being operated in local level
               in partnership of concerning agencies. Necessity of public awareness programs has been sensed because
                the fact that Nepal is not yet completely literate and law and order is weakening and the habit of people 
                of being careless deliberately. Public awareness programs have been conducted through the median of volunteers 
                involved in Bishal Foundation. Public awareness programs related to Sanitation and health are being conducted in 
                rural areas. This foundation has been inspiring new generations to conserve culture, interact for youth leadership 
                and development, organizing seminars and trainings, performing street drama against drug abuse, radio jingle, producing
                 and broadcasting P.S., operating financial literacy programs and other programs of gender discriminations and pedestrian 
                 security and also public awareness programs regarding corona virus. Programs including climate changes and its conservation,
                  environment and forestation programs, entrepreneurship and self-employment programs, children development,
               children education programs are in top priority of this foundation. 
               
              </p>
            </div>
            <div className="image">
              <img src="./images/aware4.jpg" alt="" />
            </div>
          </div>
        </div>
        <div className="gallerys">
            <p><span>Some Photos Of Awareness Program</span></p>
            <div className="imagess">
              <div className="img1">
                  <a href=""><img src="./images/aware2.jpg" alt="" /></a>
              </div>
              <div className="img1">
                  <a href=""><img src="./images/aware3.jpg" alt="" /></a>
              </div>
              <div className="img1">
                  <a href=""><img src="./images/aware0.jpg" alt="" /></a>
              </div>
            </div>
        </div>
      </div>
      <GoogleMap/>
      <Footer/>
    <Copyright/> 
        </div>
    )
}

export default Awareness
