import React,{useState,useEffect,useContext} from 'react'
import {Link} from 'react-router-dom';
import './event.css'
import './cont.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { isAuthenticated, signout } from '../../auth';
import { GlobalState } from '../../../../GlobalState';
import { Fragment } from 'react';
import swal from 'sweetalert';
import { deleteContact } from '../../Apiadmin';

const Acontact=({history})=> {
    const state = useContext(GlobalState)
    const [events]=state.contactsAPI.contacts
    const{user,token}=isAuthenticated();
    
const refreshPage = ()=>{
    window.location.reload();
 }

 const destroy = postId => {
    deleteContact(postId, user._id, token).then(data => {
        if (data.error) {
            console.log(data.error);
        } else {
            swal({
                title: "Success",
                text: "Successfully Deleted",
                icon: "success",
                button: "Ok",
              }
        )
        refreshPage()
        }
    });
};

    
    return (
        <div>
            <div className="dash">
                <div className="dashside1">
                    <h2>Bishal Foundation</h2>
                    <p className="dashp"><Link to='/admin/dashboard' id="alink"><i class="fas fa-tachometer-alt"></i> Dashboard</Link></p>
                    <div className="dash-list">
                    <Link to='/event' id="alink" > <li >Events</li></Link>
                         <Link  to='/story' id="alink" ><li>Stories</li></Link>
                         <Link to='/partner' id="alink"><li>Partners</li></Link>
                         <Link to='/addpressrelease' id="alink"><li>Press Release</li></Link> 
                         <Link to='/contactdetails'id="alink"><li className='active'>Contacts</li></Link>
                        <li>Internship</li>
                        <li>Volunteering</li>
                    </div>
                </div>
                <div className="dashside2">
                <div className="dashhead">
                        <div className="dashright">
                            <div className="img">   
                            </div>
                            {isAuthenticated()&&(
                            <Fragment>
                            <div className="dropdown">
                                <p className="dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                    Admin
                                </p>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <li><i class="fas fa-user"></i> Profile</li>
                                    <li><i class="fas fa-cog"></i> Setting</li>  
                                              <li onClick={()=>signout(()=>{
                                                    history.push('/')
                                                })}>
                                                
                                                <Link to='/' id="alin" ><i class="fas fa-sign-out-alt"></i> Logout</Link>
                                            
                                              </li>
                                </ul>
                            </div>
                            </Fragment>
                            )}
                        </div>
                    </div>
                    <div className="tableb pt-5 pl-5">
                        <table >
                            <thead>
                                <tr >
                                    <th>S.N</th>
                                    <th >Name</th>
                                    <th >Email</th>
                                    <th  >Message</th>
                                    <th>Action</th>
                                </tr>  
                            </thead>
                            <tbody>
                            {
                                            events.map((contact,i)=>(
                                                <tr key={contact._id}>
                                                    <td>{i+1}</td>
                                                    <td>{contact.name}</td>
                                                    <td>{contact.email}</td>
                                                    {/* <td>{event.date}</td>
                                                    <td>{event.venue}</td> */}
                                                    <td ><div id="st">
                                                    <p style={{width:'350px' ,textAlign:'justify'}}>{contact.message}</p></div></td>
                                                    <td id="bdf">
                                                            
                                                            <div className="upds">
                                                                <button type="button" onClick={() => destroy(contact._id)}><i class="fas fa-trash"></i></button> 
                                                            </div>
                                                    
                                                    </td>
                                                    </tr>
                                                ))
                                            } 
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Acontact
