import React from 'react'
import './sdgs.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';
function Sdgs() {

    return (
        <div >
            <Header/>
            <Mobilenav/>
            <div className="community-img">
                    <img src="./images/bishal3.jpg" alt="" />
                </div>
            <div className="sdgss">

          <div className="art">
          <div className="col-md-12 contentss">
            <div className=" dfc">
              <p className="pws">SUSTAINABLE DEVELOPMENT GOALS</p>
              <p className="textss">Bishal Foundation has been co-ordinating with Government of Nepal,
               Civil Society, Private Sectors and International donors with an objective of attaining sustainable 
               development for speculated beautiful future. Among the sustainable development, we have been mostly 
               working in the sectors including Poverty Alleviation (Goal No. 1), Zero Famine (Goal No. 2), Excellent
                Health and Prosperous Lifestyle (Goal No. 3), Qualitative Education (Goal No. 4), Decorous Work and Finance 
                Increment (Goal No. 8), Industry Promotion and Infrastructure (Goal No. 9), Sustainability of Urban and Communities 
                (Goal No. 10), Responsibility in consumer and production (Goal No. 11) and Works related to climate (Goal No. 12).
                 We have been operating awareness programs, skill development trainings, studies, researches, conference boot camps,
                  health camps, modern libraries, financial literature programs in local level to attain aforementioned goals. We have been 
                  providing 
              facilitation in work of government through social sector in order to achieve goals within 2030 AD. 
              </p>
            </div>
            <div className="image">
              <img src="./images/sdgs.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <GoogleMap/>
      <Footer/>
    <Copyright/>
        </div>
    )
}

export default Sdgs
