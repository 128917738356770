import React,{useState,useEffect} from 'react'
import Home from './Home'
import Load from './Load';
import Header from './components/header/Header';
import Mobilenav from './components/header/Mobilenav';
import Copyright from './components/pages/Copyright';
import Datarepresentation from './components/pages/Datarepresentation';
import Footer from './components/pages/Footer';
import GoogleMap from './components/pages/GoogleMap';
import HeroSection from './components/pages/HeroSection';
import MeetOurTeam from './components/pages/MeetOurTeam';
import Owl from './components/pages/Owl';
import Posts from './components/pages/Posts';
import SuccessfullStories from './components/pages/SuccessfullStories';
import Whatwedo from './components/pages/Whatwedo';

import MessengerCustomerChat from 'react-messenger-customer-chat';
import Ourgallery from './components/pages/Ourgallery';
import OurPartners from './components/pages/OurPartners';
import Modelp from './components/pages/Modelp';
import './App.css'

function App() {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
    
        setTimeout(() => setLoading(false), 2000)
      
    }, [])
  
    return (
        <div className="apps">
             {loading === false ? (
                <div>
                   <Modelp/>
                   <Mobilenav/>
        <Header/> 
        <HeroSection/>
          <Datarepresentation />   
          <Whatwedo/>   
        <Posts/> 
        <MeetOurTeam/>    
        <SuccessfullStories/>  
        <Owl/>  
        <Ourgallery/>
        <OurPartners/>
        <GoogleMap/>
        <Footer/>
        <Copyright/> 
        <div className="messenger">
            <MessengerCustomerChat
          pageId="100202588229539"
          
        /> 
        </div>
            </div>
      ) : (
          
        <Load />
      )}
        </div>
    )
}

export default App
