import react from "react";
import "./OurPartner.css";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";


const options = {
    responsiveClass: true,
    nav: false,
    loop:true,
    dots: false,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
        
        1300: {
            items: 4,

        },
        1400: {
            items: 4,

        }
        
    },
};

function OurPartners() {
    
  
    return (
        <div>
            <div className="wth pt-5">
                <h1 className="initial-heading pb-3" id="le"> Our Partners</h1>
                <div>
                    <nav className="nvss">
                        <div className="nav nav-tabs tsb " id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true">All</button>
                            <button className="nav-link " id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="false">Government</button>
                            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Private</button>
                            <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Doner</button>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active alls" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">
                        <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
                                <div className="all_item">
                                    <img src="./images/partner/all1.jpg" alt="" />
                                    <p>
                                        नेपाल सरकार <br />
                                        उद्योग, वाणिज्य तथा आपूर्ति मन्त्रालय
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/all1.jpg" alt="" />
                                    <p>
                                    बागमती प्रदेश सरकार <br />
                                    उद्योग, पर्यटन, वन तथा बातावरण मन्त्रालय 
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/heifer.gif" alt="" />
                                    <p>
                                    हेपफ्र इन्टरनेशनल नेपाल
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/worldvision.jpg" alt="" />
                                    <p>
                                    वर्ल्ड भिजन इन्टरनेशनल
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/cin.jpg" alt="" />
                                    <p>
                                    नेपाल उधोग परिसंघ – (सिएनआई )
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/rbb.jpg" alt="" />
                                    <p>
                                    राष्ट्रिय बाणिज्य बैंक लिमिटेड 
                                    </p>
                                </div>
                            </OwlCarousel>
                        </div>
                        <div className="tab-pane fade show govs" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
                                <div className="all_item">
                                    <img src="./images/partner/all1.jpg" alt="" />
                                    <p>
                                        नेपाल सरकार <br />
                                        उद्योग, वाणिज्य तथा आपूर्ति मन्त्रालय
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/all1.jpg" alt="" />
                                    <p>
                                    बागमती प्रदेश सरकार <br />
                                    उद्योग, पर्यटन, वन तथा बातावरण मन्त्रालय 
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/all1.jpg" alt="" />
                                    <p>
                                        नेपाल सरकार <br />
                                        युवा तथा खेलकुद मन्त्रालय
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/all1.jpg" alt="" />
                                    <p>
                                        बागमती प्रदेश सरकार <br />
                                        सामाजिक विकास मन्त्रालय 
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/all1.jpg" alt="" />
                                    <p>
                                    बुटवल उपमहानगरपालिका,<br /> बुटवल 
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/all1.jpg" alt="" />
                                    <p>
                                        गण्डकी प्रदेश सरकार <br />
                                        उद्योग, पर्यटन, वन तथा बातावरण मन्त्रालय
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/rbb.jpg" alt="" />
                                    <p>
                                    राष्ट्रिय बाणिज्य बैंक लिमिटेड 
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/samaj.jpg" alt="" />
                                    <p>
                                    समाजकल्याण परिषद् 
                                    </p>
                                </div>
                            </OwlCarousel>
                        </div>
                        <div className="tab-pane fade privs" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
                                
                                <div className="all_item">
                                    <img src="./images/partner/cin.jpg" alt="" />
                                    <p>
                                    नेपाल उधोग परिसंघ – (सिएनआई )
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/niryat.jpg" alt="" />
                                    <p>
                                    नियार्त ब्यवसायी महासंघ नेपाल 
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/sunrise.jpg" alt="" />
                                    <p>
                                    सनराइज बैंक
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/uniglobe.jpg" alt="" />
                                    <p>
                                    युनिग्लोव कलेज
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/samridhi.jpg" alt="" />
                                    <p>
                                    समृद्धि कलेज 
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/edmark.jpg" alt="" />
                                    <p>
                                    एड मार्क कलेज
                                    </p>
                                </div>
                            </OwlCarousel>
                        </div>
                        <div className="tab-pane fade donps" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                        <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
                                <div className="all_item">
                                    <img src="./images/partner/heifer.gif" alt="" />
                                    <p>
                                    हेपफ्र इन्टरनेशनल नेपाल
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/worldvision.jpg" alt="" />
                                    <p>
                                    वर्ल्ड भिजन इन्टरनेशनल
                                    </p>
                                </div>
                                <div className="all_item">
                                    <img src="./images/partner/globalfair.jpg" alt="" />
                                    <p>
                                    ग्लोवल फ्येरनेस इनिसेटिभ 
                                    </p>
                                </div>
                                
                            </OwlCarousel>
                        </div>
                    </div>
                </div> 
                <div className="partners_button">
                    <Link to="/allpartners"><button>
                        View All
                    </button></Link>
                </div>        
            </div>
        </div>
    )
}

export default OurPartners
