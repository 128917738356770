import React from 'react';
import './sdgs.css';
import './researchdialogue.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Researchdialogue() {
    return (
        <div>
             <Header/>
            <Mobilenav/>
            <div className="sdgss">
        <div className="art">
          <div className="col-md-12 contentss">
            <div className=" dfc">
              <p className="sspwss">Research And Plicy Dialogue</p>
              <p className="textss">Bishal foundation usually performs policy dialogues focusing towards a goal of 
              sustainable development. Moreover, it also performs national seminar based on study and researches. 
              It has been organizing interactions in the subject from time relevant subjects to financial development,
               start-up entrepreneur, education, health, tourism, sports, climate change, youth leadership and other miscellaneous
               subjects.
               The goal of such dialogues is to facilitate policy, program and budget structure made by the government.  
               
              </p>
            </div>
            <div className="image">
              <img src="./images/rpd.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <GoogleMap/>
      <Footer/>
    <Copyright/>
        </div>
    )
}

export default Researchdialogue
