import React,{useEffect} from 'react'
import './Whatwedo.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from 'aos'
import 'aos/dist/aos.css'
import privacy from '../../image/privacy.jpg'

function Whatwedo() {

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        arrrows:true,
        slidesToScroll: 1,
        cssEase: "linear",
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1124,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
               
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    }
    useEffect(()=>{
        Aos.init({duration:2000});
    },[])

    return (
        <div className="what-we-do pt-5" data-aos="fade-down">
            <div className="data-name pt-3">
                    <h1 id="le"> What We Do</h1>
            </div>
            <p id="wedss" className="pb-4"> Bishal Foundation is charitable organization of entrepreneurs devoted in sustainable
             development. Programs based on goal of sustainable development, humanitarian aid, skill development training,
             public awareness programs and community development are being performed through this foundation.
            </p>
            <Slider {...settings}>
            <div className="card-wrapper " >
                <div className="carda">
                    <div className="card-image">
                        <img src="./images/sdg.jpg" />
                    </div>
                    <div className="details">
                        <p>SDGs (Sustainable Development Goals)</p>
                    </div>
                </div>
            </div>
            <div className="card-wrapper">
                <div className="carda">
                    <div className="card-image">
                        <img src="./images/cbp.jpg" />
                    </div>
                    <div className="details">
                    <p> Community Based Programs </p>
                    </div>
                </div>
            </div>
            <div className="card-wrapper">
                <div className="carda">
                    <div className="card-image">
                        <img src="./images/skill.jpg" />
                    </div>
                    <div className="details">
                    <p> Skill Development Training </p>
                    </div>
                </div>
            </div>
            <div className="card-wrapper">
                <div className="carda">
                    <div className="card-image">
                        <img src="./images/humanaid.jpg" />
                    </div>
                    <div className="details">
                    <p>Humanitarian Aid</p>
                    </div>
                </div>
            </div>
            <div className="card-wrapper">
                <div className="carda">
                    <div className="card-image">
                        <img src="./images/startup.jpg" />
                    </div>
                    <div className="details">
                    <p>Startup Idea Call Program</p>
                    </div>
                </div>
            </div>
            <div className="card-wrapper">
                <div className="carda">
                    <div className="card-image">
                        <img src={privacy} />
                    </div>
                    <div className="details">
                    <p>Research and Policy Dialogue</p>
                    </div>
                </div>
            </div>
        </Slider>
        </div>
    )
}

export default Whatwedo
