import React from 'react'
import './volunteer.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Volunteer() {
    return (
        <div>
            <Header/>
            <Mobilenav/>
            <div className="vols">
                <p>Present Bishal Foundation Volunteers</p>
            </div>
            <div className="volunteer">
                <div className="nvol">
                    <p>Number of Present Volunteers: 10</p>
                    <hr />
                </div>
                <div className="volunteers">
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/presentvolunteer/yatri.jpg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            <p className="name"><span>Name:</span> Yatri Limbu</p>
                             <p className="address"><span>Faculty:</span>BSW First Year</p>
                             <p className="address"><span></span>Samridhi College</p> 
                            <p className="bloodg"><span>Blood Group:</span>AB<sup>+</sup></p>
                            {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
                        </div>
                    </div>
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/presentvolunteer/sudeep.jpg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            <p className="name"><span>Name:</span> Sudeep Aryal</p>
                            <p className="address"><span>Faculty:</span>BSW First Year</p>
                             <p className="address"><span></span>Samridhi College</p> 
                            <p className="bloodg"><span>Blood Group:</span>A<sup>+</sup></p>
                            {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
                        </div>
                    </div>
                    
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/presentvolunteer/kabita.jpg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            <p className="name"><span>Name:</span> Kabita Rai</p>
                            <p className="address"><span>Faculty:</span>BSW First Year</p>
                             <p className="address"><span></span>Samridhi College</p> 
                            <p className="bloodg"><span>Blood Group:</span>O<sup>+</sup></p>
                            {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
                        </div>
                    </div>
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/presentvolunteer/prasen.jpg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            <p className="name"><span>Name:</span> Prasen Chaguthi</p>
                            <p className="address"><span>Faculty:</span>BSW First Year</p>
                             <p className="address"><span></span>Samridhi College</p> 
                            <p className="bloodg"><span>Blood Group:</span>A<sup>+</sup></p>
                            {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
                        </div>
                    </div>
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/presentvolunteer/sarina.jpg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            <p className="name"><span>Name:</span> Sarina Dhakal</p>
                            <p className="address"><span>Faculty:</span>BSW First Year</p>
                             <p className="address"><span></span>Samridhi College</p> 
                            <p className="bloodg"><span>Blood Group:</span>O<sup>+</sup></p>
                            {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
                        </div>
                    </div>
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/bfoundationlogo.svg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            {/* <p className="name"><span>Name:</span> Sarina Dhakal</p>
                            <p className="address"><span>Faculty:</span>BSW First Year</p>
                             <p className="address"><span></span>Samridhi College</p> 
                            <p className="bloodg"><span>Blood Group:</span>O<sup>+</sup></p> */}
                            {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
                        </div>
                    </div>
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/bfoundationlogo.svg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            {/* <p className="name"><span>Name:</span> Sarina Dhakal</p>
                            <p className="address"><span>Faculty:</span>BSW First Year</p>
                             <p className="address"><span></span>Samridhi College</p> 
                            <p className="bloodg"><span>Blood Group:</span>O<sup>+</sup></p> */}
                            {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
                        </div>
                    </div>
                    <div className="volunteer_member">
                        <div className="cardss">
                            <div className="layer"></div>
                            <div className="content">
                                <img src="./images/bfoundationlogo.svg" alt="" />
                            </div>
                        </div>
                        <div className="name_detail">
                            {/* <p className="name"><span>Name:</span> Sarina Dhakal</p>
                            <p className="address"><span>Faculty:</span>BSW First Year</p>
                             <p className="address"><span></span>Samridhi College</p> 
                            <p className="bloodg"><span>Blood Group:</span>O<sup>+</sup></p> */}
                            {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
                        </div>
                    </div>
                    
                </div>
            </div>
            <GoogleMap/>
      <Footer/>
    <Copyright/> 
        </div>
    )
}

export default Volunteer
