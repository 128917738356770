import React from 'react'
import './sdgs.css'
import './aboutus.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Aboutus() {
    return (
        <div >
             <Header/>
            <Mobilenav/>
            <div id="aboutimg">
              <img src="./images/bishal4.jpg" alt="" />
            </div>
            <div className="sdgss">
        <div className="art">
          <div className="col-md-12 contentss">
            <div className=" dfc">
              <p className="spwsss">About Us</p>
              <p className="textss">The Bishal Foundation is a non-profit organization working for the prosperity of Nepal.
               It is a charitable organization of entrepreneurs who stepped forward intending to contribute to the development
                and prosperity of Nepal through social activities. This foundation which does not discriminate on basis of caste,
                 religion, gender, race, political belief mainly conducts programs in financial development and entrepreneurship 
                 development.
                Bishal Foundation is charitable organization of entrepreneurs devoted in sustainable development. Programs based on 
                goal of sustainable development, humanitarian aid, skill development training, public awareness programs and community
                 development are being performed through this foundation.
              </p>
            </div>
            <div className="pt-5 pl-3 mt-4" id="aboutvideo">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Q2rocS7kdto" title="YouTube video player" frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-md-12 contentss">
            <div className=" dfc">
              <p className="spwsss">Foundation Goals and Objectives</p>
              <p className="textss">
                <ol type="1">
                  <li>To facilitate in order to achieve the goal for sustainable
                     development of Nepal in appeal of federal government, provincial government, local level and other partner agencies.</li>
                     <li>	To operate sublimation programs of sustainable development for all-round development of community. </li>
                     <li>	To operate programs of humanitarian aid.</li>
                     <li> To operate skill development trainings for poverty alleviation. </li>
                     <li> To organize national level policy dialogue. </li>
                     <li> Support creation of a sustainable economy</li>
                     <li>	Promote entrepreneurship among youths by providing knowledge, skills, abilities, and necessary financial support to nurture startups</li>
                    <li>Generate employment opportunities through startups and link individuals with production. </li>
                </ol>
              </p>
            </div>
            <div className="pt-5 pl-3 mt-4" id="objectivegoals">
              <img src="./images/goals.png" alt="" />
            </div>
          </div>
          <p className="spwsss">Our History</p>
          <div className="col-md-12 contentss">
            <div className=" dfc">
              <p className="textss" style={{fontSize:'18px',marginTop:'-30px'}}>
              Nepal is a beautiful country full of natural resources. Though it has immense possibilities in sectors such as 
              agriculture, tourism, and industry, the development of the country has not been as expected. The situation of Education,
               Health, Entrepreneurship, and Children’s rights is not satisfactory. Developing a positive attitude among the youth has 
               still been a challenging task. The players in Nepal are very hardworking. But due to limited means and resources, they 
               have not been able to utilize their full potential and produce the best results. In this context, a group of successful entrepreneurs
                from different sectors established the Bishal Organization on 14th Poush 2074 B.S. (December 29, 2017) intending to contribute to the
                 economy of the country. Its main objective is to help the youth in new startup companies, invest in their innovative ideas, and run 
                 a multi-purpose company by a single name. While working constantly to accomplish the goals, we focus on the prosperity of the country 
                 and for this, different sectors and areas of development should be successful. The Bishal Foundation was established as a non-profit
                  organization to manage social responsibility programs of the corporate organizations and help the government by conducting different
                   social activities. Besides Bishal Organization and its sister companies,
               the Foundation has been conducting various programs in collaboration and cooperation with corporate houses,
               government and donor agencies. The sole purpose of these programs is to make people happy by helping, energizing and boosting their confidence.
                We are constantly working on this project and we shall continue to work. 
              </p>
            </div>
          </div>
        </div>
      </div>
      <GoogleMap/>
      <Footer/>
    <Copyright/>
        </div>
    )
}

export default Aboutus
