import React from 'react'
import './sdgs.css';
import './internship.css';
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Internship() {
    return (
        <div >
           <Header/>
            <Mobilenav/>
            <div className="careerss">
                <img src="./images/bishal5.jpg" alt="" />
                <div className="carrs">
                    <p><span>Careers at Bishal Foundation</span></p>
                    <div className="ctt">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                             Amet molestias perferendis corporis alias. Soluta
                              officiis eveniet est expedita facilis, sed ducimus deserunt sit, cum, 
                            et doloribus cupiditate placeat deleniti! Ducimus!</p>
                    </div>
                </div>
            </div>
            <div className="sdgss" id="career">
        <div className="art">
          <div className="col-md-12 contentss">
            <div className=" dfc">
              <p className="pssws">Bishal Foundation Internship Program</p>
              <p className="textss">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
               
              </p>
            </div>
            <div className="image">
              <img src="./images/intern.jpg" alt="" />
            </div>
          </div>
          <div className="selection">
              <p><span>Selection process</span></p>
              <div className="spss">
                  <ol>
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit</li>
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit</li>
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit</li>
                  <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit</li>
                  </ol>
              </div>
          </div>
          <div className="currentop">
              <p><span>Current Opportunities</span></p>
              <div className="spss">
                  <p>No opportunities at this time. Please check again soon.</p>
              </div>
          </div>
          <div className="currentop">
              <p><span>Contact</span></p>
              <div className="spss">
                  <p>For questions related to internship, please email the <a href="https://mail.google.com/mail/u/0/#inbox?compose=new"> Bishl Foundation Internship Program.</a></p>
              </div>
          </div>
        </div>
      </div>
      <GoogleMap/>
      <Footer/>
    <Copyright/>  
        </div>
    )
}

export default Internship
