import React,{useState,useEffect} from 'react'
import axios from 'axios'
function ContactAPI() {
    const [contacts,setContact]=useState([])


    const getContact = async ()=>{
        const res = await axios.get(`/api/showscontact`)
        setContact(res.data)
    }
    useEffect(()=>{
        getContact()
    },[])
    return{
        contacts: [contacts,setContact],
        
    }
}

export default ContactAPI
