import React,{useState,useEffect,useContext} from 'react'
import {Link} from 'react-router-dom';
import './event.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { isAuthenticated, signout } from '../../auth';
import { GlobalState } from '../../../../GlobalState';

import { Fragment } from 'react';
import { createPartner, deletePartner, getCategories } from '../../Apiadmin';
import swal from 'sweetalert';

const Partner=({history})=> {

    const [visiblity,setVisiblity] = useState('hidden');


    // const [visibility, setClose]=useState('visible')
    const state = useContext(GlobalState)
    const [partners]=state.partnerAPI.partners
    
    
    const{user,token}=isAuthenticated();
    const [values,setValues]=useState({
        name:'',
        categories:[],
        image:'',
        loading:false,
        success:false,
        error:'',
        redirectToProfile:false,
        formData:''
    })
    const {
        name,
        categories,
        loading,
        success,
        error,
        redirectToProfile,
        formData

    } =values;

  //load categories and set form data
  const init=()=>{
    getCategories().then(data=>{
        if(data.error){
            setValues({...values,error:data.error})
        } else{
            setValues({...values,categories:data,formData:new FormData});//new form data defined
        }
    })
}

useEffect(()=>{
    init();
  },[])

const handleChange=name=>partner=>{
    const value= name==='image' ? partner.target.files[0] : partner.target.value
    formData.set(name,value)
    setValues({...values,[name]:value})
}


const refreshPage = ()=>{
    window.location.reload();
 }

 const destroy = postId => {
    deletePartner(postId, user._id, token).then(data => {
        if (data.error) {
            console.log(data.error);
        } else {
            swal({
                title: "Success",
                text: "Successfully Deleted",
                icon: "success",
                button: "Ok",
              }
        )
        refreshPage()
        }
    });
};

const clickSubmit=(story)=>{
    story.preventDefault()
    setValues({...values,error:'',loading:true});
    createPartner(user._id,token,formData)
    .then(data=>{
        if(data.error){
            setValues({...values,error:data.error})
        }else{
            setValues({
               ...values,name:'',image:'',
               loading:false,success:true 
            },refreshPage());
        }

    });
};
const showError=()=>(
<div className="alert alert-danger" style={{display: error ? '':'none'}}>
 {error}
</div>

);
const showSuccess=()=>(
    <div className="alert alert-info" style={{display:success ? '':'none'}}>
     <h2>Partner is added!</h2>
    </div>
    
    );
const showLoading=()=>(
        loading && (
        <div className="alert alert-success" >
         <h2>Loading....</h2>
        </div>
        )
        
        );
    const newPostForm=()=>(
		<div className="modal-dialog mt-5 toggleform">	
        <div className="modal-content">
            <div className="modal-body modal-body-sub_agile">
                <div className="modal_body_left modal_body_left1">
               <button onClick={()=>{setVisiblity("hidden")}} style={{float:'right',fontSize:'22px',
               marginTop:'-10px', border:'none',backgroundColor:'#fff'}}> <i class="fas fa-times"></i></button>
        <h3 className="agileinfo_sign" id="ap">Add Partner</h3>
        {showLoading()}
        {showError()}
        {showSuccess()}
        <form>
             <div className="mb-3">
                <label  className="form-label" id="label">Partner name</label>
                <input type="text" className="form-control" name="name" required="required" onChange={handleChange('name')} value={name}/>
            </div>
            <div className="styled-input mb-3">
            <label  className="form-label pt-3" id="label">Select Category</label>
                <select class="form-select" aria-label="Default select example" onChange={handleChange('category')}>
                    <option>Please select</option>
                    {categories && categories.map((c,i)=>(
                        <option key={i} value={c._id}>{c.name}</option>
                    ))}
                </select>
            </div>
            <div className="styled-input">
                <label  className="form-label" id="label">Image</label>
                <input type="file" className="form-control" placeholder="choose Image" name="image"  onChange={handleChange('image')} accept="image/*" required="required" />
            </div>
            <div id="btns">
                <button onClick={clickSubmit} className="btnss">
                    Add Partner
                </button>
            </div>
        </form>
    </div>
    </div>    
            </div>
        </div>
     );
    return (
        <div>
            <div className="dash">
                <div className="dashside1">
                    <h2>Bishal Foundation</h2>
                    <p className="dashp"><Link to='/admin/dashboard' id="alink"><i class="fas fa-tachometer-alt"></i> Dashboard</Link></p>
                    <div className="dash-list">
                    <Link to='/event' id="alink" > <li >Events</li></Link>
                         <Link  to='/story' id="alink" ><li>Stories</li></Link>
                         <Link to='/partner' id="alink"><li className="active">Partners</li></Link>
                         <Link to='/addpressrelease' id="alink"><li>Press Release</li></Link> 
                         <Link to='/contactdetails'id="alink"><li>Contacts</li></Link>
                        <li>Internship</li>
                        <li>Volunteering</li>
                    </div>
                </div>
                <div className="dashside2">
                <div className="dashhead">
                        <div className="dashright">
                            <div className="img">   
                            </div>
                            {isAuthenticated()&&(
                            <Fragment>
                            <div className="dropdown">
                                <p className="dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                    Admin
                                </p>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <li><i class="fas fa-user"></i> Profile</li>
                                    <li><i class="fas fa-cog"></i> Setting</li>  
                                              <li onClick={()=>signout(()=>{
                                                    history.push('/')
                                                })}>
                                                
                                                <Link to='/' id="alin" ><i class="fas fa-sign-out-alt"></i> Logout</Link>
                                            
                                              </li>
                                </ul>
                            </div>
                            </Fragment>
                            )}
                        </div>
                    </div>
                    <div className="eventss pb-5">
                        <div className=" mb-3 button">
                            <button onClick={()=>{setVisiblity("visible")}}> Partner</button>
                        </div>
                        <div style={{visibility:visiblity}} id="toggle">
                           
                            {newPostForm()}
                        </div>
                        <div className="table">
                            <div className="pl-5 pr-5 ">
                                <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col"style={{width:'50px'}}>S.N</th>
                                                <th scope="col"style={{width:'150px'}}>Image</th>
                                                <th scope="col" style={{width:'150px'}} >Name</th>
                                                <th scope="col"style={{width:'120px'}}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        
                                            {
                                            partners.map((partner,i)=>(
                                                <tr key={partner._id}>
                                                    <td>{i+1}</td>
                                                    <td> <img src={'/'+partner.image} className="card-img-top" alt="..." height="150px" width="50px"/></td>
                                                    <td>{partner.name}</td>
                                                    {/* <td>{event.date}</td>
                                                    <td>{event.venue}</td> */}
                                                
                                                    <td id="bdf">
                                                            
                                                            <div className="upds">
                                                                <button type="button" className="button1" ><i class="fas fa-edit"></i></button> 
                                                                <button type="button" onClick={() => destroy(partner._id)} ><i class="fas fa-trash"></i></button> 
                                                            </div>
                                                    
                                                    </td>
                                                    </tr>
                                                ))
                                            } 
                                        
                                        </tbody>
                                    </table>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partner
