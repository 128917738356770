import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Navone from '../../../header/Navone';
import { isAuthenticated } from '../../auth';
import {  createPhoto } from '../../Apiadmin';
import Mobilenav from '../../../header/Mobilenav';



const Addphoto=()=> {
    const{user,token}=isAuthenticated();
    const [values,setValues]=useState({
        image:'',
        loading:false,
        success:false,
        error:'',
        redirectToProfile:false,
        formData:''
    })
    const {
        loading,
        success,
        error,
        redirectToProfile,
        formData

    } =values;

    const init=()=>{
       
                setValues({...values,formData:new FormData});//new form data defined
            
    }
    
    useEffect(()=>{
        init();
      },[])
    
    const handleChange=name=>event=>{
        const value= name==='image' ? event.target.files[0] : event.target.value
        formData.set(name,value)
        setValues({...values,[name]:value})
    }
    const clickSubmit=(event)=>{
        event.preventDefault()
        setValues({...values,error:'',loading:true});
        createPhoto(user._id,token,formData)
        .then(data=>{
            if(data.error){
                setValues({...values,error:data.error})
            }else{
                setValues({
                   ...values,image:'',
                   loading:false,success:true 
                });
            }

        });
    };
const newPostForm=()=>(
		<div className="modal-dialog mt-5">	
        <div className="modal-content">
            <div className="modal-body modal-body-sub_agile">
                <div className="modal_body_left modal_body_left1">
        <h3 className="agileinfo_sign" id="ap">Add Photo</h3>
        {showError()}
        {showSuccess()}
        <form>
            <div className="styled-input">
                <input type="file" className="form-control" placeholder="choose Image" name="product_image"  onChange={handleChange('image')} type="file" name="product_image" accept="image/*" required="required" />
            </div>
            <div id="btns">
                <button onClick={clickSubmit} className="btnss">
                    Add Photo
                </button>
            </div>
        </form>
    </div>
    </div>    
            </div>
        </div>
     );
     const adminLinks=()=>{
         return(
     <div className="card">
         <h4 className="card-header">Admin Links</h4>
        <ul className="list-group">
       <li className="list-group-item">
           <Link className="nav-link" to="/createevent">Add Photo</Link>
       </li>
        </ul>
     </div>
 );
         } 
 const showError=()=>(
 <div className="alert alert-danger" style={{display: error ? '':'none'}}>
  {error}
 </div>
 
 );
 const showSuccess=()=>(
     <div className="alert alert-info" style={{display:success ? '':'none'}}>
      <h2>Image is added!</h2>
     </div>
     
     );
 const showLoading=()=>(
         loading && (
         <div className="alert alert-success" >
          <h2>Loading....</h2>
         </div>
         )
         
         );
    return (
        <>
        <Navone/>
        <Mobilenav/>
        <h4 id="back"className="pt-4"><Link to="/admin/dashboard" className="bc"><i class="fas fa-arrow-circle-left"></i> Back</Link></h4>
       <div  className="container">
        <div className="row">
        <div className="col-md-12">
            {adminLinks()}
        </div> 
        <div className="col-md-6">      
            {showLoading()}
            {newPostForm()}
         </div>
         </div>
         </div>  
        </>
    )
}

export default Addphoto