import React from 'react'
import './copy.css'

function Copyright() {
    return (
        <div>
            <div className="copyright">    
                <p>© Copyright <strong>Bishal Foundation </strong> .All Rights reserved.</p>
            </div>
        </div>
    )
}

export default Copyright
