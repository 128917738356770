import React from 'react';
import './donate.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Donate() {
    return (
        <div>
             <Header/>
            <Mobilenav/>
            <div className="donatebanner">
                {/* <img src="./images/bishal6.jpg" alt="" /> */}
                <div className="doante_card">
                        <h2><span>Donate</span></h2>
                        <h4>BF needs your help!</h4>
                        <p>We have several worthwhile projects coming up that need your sponsorship. Please review and click to Donate.
                             Your generosity
                            is appreciated ! We use our bank account or direct contact us.</p>

                            <div className="accountdeails">
                               <p><span> Bank Name:</span> Sunrise Bank Ltd.</p>
                               <p><span> Account Holder Name:</span> Bishal Foundation</p>
                               <p><span> Account No.:</span> 0020786071701001</p>
                               <p><span> Branch:</span> Gairidhara</p>
                            </div>
                </div>
            </div>
            <GoogleMap/>
      <Footer/>
    <Copyright/> 
        </div>
    )
}

export default Donate
