import React from 'react'
import Header from '../header/Header';
import Footer from '../pages/Footer';
import GoogleMap from '../pages/GoogleMap';
import Copyright from '../pages/Copyright';
import suman from '../../image/sangam.JPG'
import './advisory.css'
import Mobilenav from '../header/Mobilenav';

function Sec() {
    return (
        <div>
            <Header/>
            <Mobilenav/>
            <div className="advisor_board">
                <div className="davisor_head">
                    <h1>Meet Our Secreatariat  Team</h1>
                    <p>Meet our leaders: an experienced team that
                         combines strong functional expertise and diverse
                          perspectives to set strategic direction and meet
                           emergent challenges.</p>
                </div>
                <p className="age">Secreatariat  Group Executives</p>
                <div className="advisor_team">
                    <div className="advisor_member">
                        <div className="image">
                            <img src="./images/secreatariat/Bishal-Gaire.jpg" alt="" />
                        </div>
                        <div className="advisor_content">
                                <p className="name">Mr. Bishal Gaire </p>
                                <p className="job">Executive Director </p>
                                <div className="media-icons">
                                    <a href="#"><i className="fab fa-facebook-f" /></a>
                                    <a href="#"><i className="fab fa-twitter" /></a>
                                    <a href="#"><i className="fab fa-youtube" /></a>
                                </div>
                        </div>
                    </div>
                    <div className="advisor_member">
                        <div className="image">
                            <img src={suman} alt="" />
                        </div>
                        <div className="advisor_content">
                                <p className="name">Mr. Sangam Mahat</p>
                                <p className="job">Program Director </p>
                                <div className="media-icons">
                                    <a href="#"><i className="fab fa-facebook-f" /></a>
                                    <a href="#"><i className="fab fa-twitter" /></a>
                                    <a href="#"><i className="fab fa-youtube" /></a>
                                </div>
                        </div>
                    </div>
                    <div className="advisor_member">
                        <div className="image">
                            <img src="./images/secreatariat/baburam.jpg" alt="" />
                        </div>
                        <div className="advisor_content">
                                <p className="name">Mr. Baburam Bhandari (CA)</p>
                                <p className="job">Finance Consultant</p>
                                <div className="media-icons">
                                    <a href="#"><i className="fab fa-facebook-f" /></a>
                                    <a href="#"><i className="fab fa-twitter" /></a>
                                    <a href="#"><i className="fab fa-youtube" /></a>
                                </div>
                        </div>
                    </div>
                    <div className="advisor_member">
                        <div className="image">
                            <img src="./images/secreatariat/Arjun-Bista.jpg" alt="" />
                        </div>
                        <div className="advisor_content">
                                <p className="name">Mr. Arjun Bista</p>
                                <p className="job">Chief Program Officer</p>
                                <div className="media-icons">
                                    <a href="#"><i className="fab fa-facebook-f" /></a>
                                    <a href="#"><i className="fab fa-twitter" /></a>
                                    <a href="#"><i className="fab fa-youtube" /></a>
                                </div>
                        </div>
                    </div>
                    <div className="advisor_member">
                        <div className="image">
                            <img src="./images/secreatariat/Laxman-Adhikari.jpg" alt="" />
                        </div>
                        <div className="advisor_content">
                                <p className="name">Mr. Laxman Adhikari </p>
                                <p className="job">Chief Program Officer</p>
                                <div className="media-icons">
                                    <a href="#"><i className="fab fa-facebook-f" /></a>
                                    <a href="#"><i className="fab fa-twitter" /></a>
                                    <a href="#"><i className="fab fa-youtube" /></a>
                                </div>
                        </div>
                    </div>
                    <div className="advisor_member">
                        <div className="image">
                            <img src="./images/secreatariat/Mr.-Rajkumar-Adhikari.jpg" alt="" />
                        </div>
                        <div className="advisor_content">
                                <p className="name">Mr. Raj Kumar Adhikari </p>
                                <p className="job">Program Coordinator</p>
                                <div className="media-icons">
                                    <a href="#"><i className="fab fa-facebook-f" /></a>
                                    <a href="#"><i className="fab fa-twitter" /></a>
                                    <a href="#"><i className="fab fa-youtube" /></a>
                                </div>
                        </div>
                    </div>
                    <div className="advisor_member">
                        <div className="image">
                            <img src="./images/secreatariat/suman1.jpg" alt="" />
                        </div>
                        <div className="advisor_content">
                                <p className="name">Mr. Suman Chhetri </p>
                                <p className="job">IT Officer</p>
                                <div className="media-icons">
                                    <a href="#"><i className="fab fa-facebook-f" /></a>
                                    <a href="#"><i className="fab fa-twitter" /></a>
                                    <a href="#"><i className="fab fa-youtube" /></a>
                                </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <GoogleMap/>
      <Footer/>
      <Copyright/>
        </div>
    )
}

export default Sec
