import React from 'react'
import './allpartner.css'
import Header from '../header/Header'
import Copyright from '../pages/Copyright'
import Footer from '../pages/Footer'
import Mobilenav from '../header/Mobilenav'

function Allpartner() {
    return (
        <div>
            <Header/>
            <Mobilenav/>
           <div className="all_partner pt-5 mt-2">
               <div className="govpart">
                   <p><span>सरकारी साझेदारी :</span></p>
               </div>
               <div className="government_partners">
                   <div className="partner">
                       <img src="./images/partner/all1.jpg" alt="" />
                       <p>नेपाल सरकार अर्थ मन्त्रालय</p>
                   </div>
                   <div className="partner">
                       <img src="./images/partner/all1.jpg" alt="" />
                       <p>नेपाल सरकार उद्योग, वाणिज्य तथा आपूर्ति मन्त्रालय</p>
                   </div>
                   <div className="partner">
                       <img src="./images/partner/all1.jpg" alt="" />
                       <p>नेपाल सरकार महिला, बालबालिका र ज्येष्ठ नागरिक मन्त्रालय</p>
                   </div>
                   <div className="partner">
                       <img src="./images/partner/all1.jpg" alt="" />
                       <p>नेपाल सरकार उद्योग, वाणिज्य तथा आपूर्ति उद्योग मन्त्रालय</p>
                   </div>
                   <div className="partner">
                       <img src="./images/partner/all1.jpg" alt="" />
                       <p>नेपाल सरकार कृषि तथा पशुपंक्षी विकास मन्त्रालय</p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/all1.jpg" alt="" />
                        <p>बागमती प्रदेश सरकार उद्योग, पर्यटन, वन तथा बातावरण मन्त्रालय </p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/all1.jpg" alt="" />
                        <p>नेपाल सरकार युवा तथा खेलकुद मन्त्रालय</p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/all1.jpg" alt="" />
                        <p>गण्डकी प्रदेश सरकार उद्योग, पर्यटन, वन तथा बातावरण मन्त्रालय</p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/all1.jpg" alt="" />
                        <p>बुटवल उपमहानगरपालिका, बुटवल </p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/samaj.jpg" alt="" />
                        <p>समाजकल्याण परिषद् </p>
                   </div>
               </div>
           </div>
           <div className="all_partner">
               <div className="govpart">
                   <p><span>सरकारी एजेन्सी साझेदारी :</span></p>
               </div>
               <div className="government_partners">
                   <div className="partner">
                       <img src="./images/partner/nta.jpg" alt="" />
                       <p>नेपाल दूरसञ्चार प्राधिकरण</p>
                   </div>
                   <div className="partner">
                       <img src="./images/partner/ntb.jpg" alt="" />
                       <p>नेपाल पर्यटन बोर्ड</p>
                   </div>
                   <div className="partner">
                       <img src="./images/partner/ntc.jpg" alt="" />
                       <p>नेपाल टेलिकम</p>
                   </div>
                   <div className="partner">
                       <img src="./images/partner/rnypn.jpg" alt="" />
                       <p>राष्ट्रिय युवा परिषद नेपाल</p>
                   </div>
                   <div className="partner" >
                       <img src="./images/partner/rma.jpg" style={{height:'90px',marginTop:'40px'}} alt="" />
                       <p>राष्ट्रिय महिला आयोग</p>
                   </div>
                   <div className="partner">
                       <img src="./images/partner/adb.jpg" alt="" />
                       <p>कृषि विकास बैंक</p>
                   </div>
                   <div className="partner">
                       <img src="./images/partner/rbb.jpg" style={{height:'90px',marginTop:'40px'}} alt="" />
                       <p>राष्ट्रिय वाणिज्य बैंक</p>
                   </div>
               </div>
           </div>
           <div className="all_partner ">
               <div className="govpart">
                   <p><span>दात्रृ साझेदारी :</span></p>
               </div>
               <div className="government_partners">
                   <div className="partner">
                       <img src="./images/partner/heifer.gif" alt="" />
                       <p> हेपफ्र इन्टरनेशनल नेपाल</p>
                   </div>
                   <div className="partner">
                       <img src="./images/partner/hs.jpg"style={{height:'90px',marginTop:'50px'}} alt="" />
                       <p>Helvetas</p>
                   </div>
                   <div className="partner">
                       <img src="./images/partner/aaid.jpg"style={{height:'90px',marginTop:'50px'}} alt="" />
                       <p>Australian AID</p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/globalfair.jpg" alt="" />
                        <p> ग्लोवल फ्येरनेस इनिसेटिभ </p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/worldvision.jpg" alt="" />
                        <p>वर्ल्ड भिजन इन्टरनेशनल</p>
                   </div>
               </div>
           </div>
           <div className="all_partner ">
               <div className="govpart">
                   <p><span>निजि क्षेत्र  साझेदारी :</span></p>
               </div>
               <div className="government_partners">
                   <div className="partner">
                        <img src="./images/partner/cin.jpg" alt="" />
                        <p>नेपाल उधोग परिसंघ – (सिएनआई )</p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/niryat.jpg" alt="" />
                        <p>नियार्त ब्यवसायी महासंघ नेपाल </p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/sunrise.jpg" alt="" />
                        <p>सनराइज बैंक</p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/uniglobe.jpg" alt="" />
                        <p>युनिग्लोव कलेज</p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/samridhi.jpg" alt="" />
                        <p>समृद्धी कलेज </p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/edmark.jpg" alt="" />
                        <p>एड मार्क कलेज</p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/mahila.jpg" alt="" />
                        <p>महिला उधमी महासंघ</p>
                   </div>
                   <div className="partner">
                        <img src="./images/partner/presidential.jpg" alt="" />
                        <p>Presidential Business School</p>
                   </div>
               </div>
           </div>
           <Footer/>
            <Copyright/>
        </div>
    )
}

export default Allpartner
