import React,{useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel} from 'react-bootstrap';
import './HeroSection.css'
function HeroSection() {
    

    return (
        <div className="hero-container" >
            <Carousel fade>
                <Carousel.Item className="cis">
                    <img
                    className="d-block w-100"
                    src="./images/slider1.jpg"
                    alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item className="cis"> 
                    <img
                    className="d-block w-100"
                    src="./images/slider2.jpg"
                    alt="Second slide"
                    />

                    {/* <Carousel.Caption>
                   
                    <div id="don">
                        <p> Bishal Foundation is a non-profitable organization working for the prosperity of Nepal. It is a 
                            charitable organization of entrepreneurs who stepped forward intending ...
                        </p>
                        <div className="dnt">
                            <button id="donate" >Donate</button>
                        </div>
                    </div>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item className="cis">
                    <img
                    className="d-block w-100"
                    src="./images/slider3.jpg"
                    alt="Third slide"
                    />

                   
                </Carousel.Item>
                <Carousel.Item className="cis">
                    <img
                    className="d-block w-100"
                    src="./images/bishal6.jpg"
                    alt="Third slide"
                    />

                    {/* <Carousel.Caption>
                    <div id="don">
                        <p> Bishal Foundation is a non-profitable organization working for the prosperity of Nepal. It is a 
                            charitable organization of entrepreneurs who stepped forward intending ...
                        </p>
                        <div className="dnt">
                            <button id="donate" >Donate</button>
                        </div>
                    </div>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item className="cis">
                    <img
                    className="d-block w-100"
                    src="./images/bishal5.jpg"
                    alt="Third slide"
                    />

                    {/* <Carousel.Caption>
                    <div id="don">
                        <p> Bishal Foundation is a non-profitable organization working for the prosperity of Nepal. It is a 
                            charitable organization of entrepreneurs who stepped forward intending ...
                        </p>
                        <div className="dnt">
                            <button id="donate" >Donate</button>
                        </div>
                    </div>
                    </Carousel.Caption> */}
                </Carousel.Item>     
                
            </Carousel>
        </div>
    )
}

export default HeroSection
