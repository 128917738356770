// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../image/logback.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#log-back{\n    width: 100%;\n    height: 100vh;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")no-repeat;\n    background-size: cover;\n}\n.si{\n    background-color: #e6e6e6;\n    border-radius: 5px;\n    padding: 30px;\n}\n\n#sin{\n    height: 35px;\n    width: 120px;\n    border: none;\n    background: rgb(240,7,157);\n    background: linear-gradient(90deg, rgba(240,7,157,1) 0%, rgba(115,37,133,1) 0%);\n    color:#ffffff;\n}\n#in{\n    padding-bottom: 10px;\n}\n#back{\n   padding-left: 50px;\n}\n.bc{\n    color:#fff;\n}\n.bc:hover{\n    color: chocolate;\n}\n\n", "",{"version":3,"sources":["webpack://src/components/admin/login.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,4DAAmD;IACnD,sBAAsB;AAC1B;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,0BAA0B;IAC1B,+EAA+E;IAC/E,aAAa;AACjB;AACA;IACI,oBAAoB;AACxB;AACA;GACG,kBAAkB;AACrB;AACA;IACI,UAAU;AACd;AACA;IACI,gBAAgB;AACpB","sourcesContent":["#log-back{\n    width: 100%;\n    height: 100vh;\n    background: url('../../image/logback.jpg')no-repeat;\n    background-size: cover;\n}\n.si{\n    background-color: #e6e6e6;\n    border-radius: 5px;\n    padding: 30px;\n}\n\n#sin{\n    height: 35px;\n    width: 120px;\n    border: none;\n    background: rgb(240,7,157);\n    background: linear-gradient(90deg, rgba(240,7,157,1) 0%, rgba(115,37,133,1) 0%);\n    color:#ffffff;\n}\n#in{\n    padding-bottom: 10px;\n}\n#back{\n   padding-left: 50px;\n}\n.bc{\n    color:#fff;\n}\n.bc:hover{\n    color: chocolate;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
