import React,{useState,useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './cstyle.css'
import Header from '../../../header/Header';
import Mobilenav from '../../../header/Mobilenav';
import Copyright from '../../../pages/Copyright';
import Footer from '../../../pages/Footer';
import GoogleMap from '../../../pages/GoogleMap';
import { createContacts } from '../../Apiadmin';
import swal from 'sweetalert';

function Contact() {
    const [values,setValues]=useState({
        name:'',
        email:'',
        message:'',
        loading:false,
        success:false,
        error:'',
        redirectToProfile:false,
        formData:''
    })
    const {
        name,
        email,
        message,
        loading,
        success,
        error,
        redirectToProfile,
        formData

    } =values;

    const init=()=>{
       
                setValues({...values,formData:new FormData});//new form data defined
            
    }
    
    useEffect(()=>{
        init();
      },[])
    
    const handleChange1=event=>{
        // const value= event.target.value
        // // formData.set(names,value)
        // setValues({...values,value})
        setValues({ ...values, [event.target.name]: event.target.value });
    }
    const clickSubmit=(event)=>{
        event.preventDefault()
        setValues({...values,error:'',loading:true});
        createContacts(values)
        .then(data=>{
            if(data.error){
                setValues({...values,error:data.error})
            }else{
                setValues({
                   ...values,name:'',email:'',message:'',
                   loading:false,success:true
                },
                swal({
                    title: "Success",
                    text: "Send Successfully",
                    icon: "success",
                    button: "Ok",
                  })
                );
            }

        });
    };

const showError=()=>(
    <div className="alert alert-danger" style={{display: error ? '':'none'}}>
     {error}
    </div>
    
    );

  
            
          
    return (
        <div>
            <Header/>
            <Mobilenav/>
            <section className="contactss">
                    <div className="content">
                        <h2><span>Contact Us</span></h2>
                        <p className="foundicon" id="bc">
                            <a href="https://www.facebook.com/bishalfoundation" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="https://www.youtube.com/channel/UC8WT9gNWmD7Y6Ou9mUKETMQ"target="_blank"><i class="fab fa-youtube"></i></a>
                        </p>
                    </div>
                    <div className="container">
                    <div className="contactInfo">
                        <div className="box">
                        <div className="icon"><i className="fas fa-map-marker-alt" /></div>
                        <div className="text">
                            <h3>Address</h3>
                            <p>Bishal Foundation<br />Anamnagar - Kathmandu, Nepal</p>
                        </div>
                        </div>
                        <div className="box">
                        <div className="icon"><i className="fas fa-phone" /></div>
                        <div className="text">
                            <h3>Phone</h3>
                            <p>977-01-5705651/52</p>
                            {/* <p id="bc">Fax: 977-1-444/445</p> */}
                        </div>
                        </div>
                        <div className="box">
                        <div className="icon"><i className="far fa-envelope" /></div>
                        <div className="text">
                            <h3>Email</h3>
                            <p>info@bishalfoundation.org</p>
                            <p>bishalfoundation2019@gmail.com</p>
                        </div>
                        </div>
                    </div>
                    <div className="contactForm">
                    {/* {showLoading()} */}
                    {showError()}
                    {/* {showSuccess()}  */}
                        <form >
                        <h2>Send Message</h2>
                        <div className="inputBox">
                            <input type="text" name="name" required="required" onChange={e => handleChange1(e)} value={name} />
                            <span>Full Name</span>
                        </div>
                        <div className="inputBox">
                            <input type="text"  name="email" required="required" onChange={e => handleChange1(e)} value={email}  />
                            <span>Email</span>
                        </div>
                        <div className="inputBox">
                            <textarea  name="message" required="required" onChange={e => handleChange1(e)} value={message}  cols="auto" rows="auto" required defaultValue={""} />
                            <span>Type your Messgae</span>
                        </div>
                        <div className="inputBox">
                            <input onClick={clickSubmit} type="submit" value="Send" />
                        </div>
                        </form>
                    </div>
                    </div>
                </section>
                <GoogleMap/>
                <Footer/>
            <Copyright/>

        </div>
    )
}

export default Contact
