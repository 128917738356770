import React from 'react'
import { Link } from 'react-router-dom'
import './owl.css'

function Owl() {
    return (
        <div>
            <div className="news_head pt-5 mt-3">
            <h1 id="le">News Coverage</h1>
            </div>
            <div className="news_coverage">
                <div className="news_show">
                    <iframe width="350" height="200" src="https://www.youtube.com/embed/FdalIzllrVw" 
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write;
                         encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <p id="prp"><span id="hes">
                                उद्यमको आइडिया दिनुस्, सहुलियत ब्याजदरमा १५ लाख ऋण पाउनुस्
                    </span></p>
                    <a href="https://deshsanchar.com/2020/11/10/436133/?fbclid=IwAR3EN8a9YZiWX0bFNqPH0we1XSILJcOqzdM06U0H3li-8GM2cb-UR9-AsTM" id="owll" target="_blank">
                        <button>
                            Read More
                        </button>
                    </a>
                </div>
                 <div className="news_show">
                    <img src="images/newsimage/news1.jpg" alt="" />
                    <p id="prp"><span id="hes">Rastriya Banijya Bank to provide soft loan to startups</span></p>
                    <a href="https://nepalpostdaily.com/englishnews/rastriya-banijya-bank-to-provide-soft-loan-to-startups/" id="owll" target="blank">
                        <button>
                            Read More
                        </button>
                    </a>
                </div>
                <div className="news_show">
                    <img src="images/bishal5.jpg" alt="" />
                    <p id="prp"><span id="hes">विशाल फाउण्डेशनको पहलमा युवा उद्यमी प्रवर्द्धन कार्यक्रम सञ्चालन</span></p>
                    <a href="https://www.lokpath.com/story/209733" id="owll" target="blank">
                        <button>
                            Read More
                        </button>
                    </a>
                </div> 
            </div>
            <div className="news_more_button">
                <Link to="/allnews"><button>View All</button></Link>
            </div>
        </div>
    )
}

export default Owl
