import React,{useContext,useState,useEffect} from 'react'
import './lstyle.css'
import { Link } from 'react-router-dom'
import { GlobalState } from '../../GlobalState'
import Aos from 'aos'
import 'aos/dist/aos.css'



function Posts() {
    const state = useContext(GlobalState)
    const [events]=state.eventsAPI.events
    const [visible, setVisible] = useState(3)

    useEffect(()=>{
        Aos.init({duration:2000});
    },[])

    return (
        <>
        <div className="wdh pt-5" data-aos="fade-left">
            <div className="heading pb-3 pt-5">
                <h1 id="le">Latest Events</h1> 
            </div>
        <div className="row" >
            <div className="eventflex">
                    {
                        events.slice(0,visible).map(event=>{
                            return ( 
                                    <div className="card " key={event._id}>
                                            <img  src={'/'+event.image} className="card-img-top" alt="..." />
                                            <div className="card-title">
                                                <p className="card-title ct">{event.title}</p>
                                            </div>
                                            <div className="tit pb-3">
                                                {/* <p id="bywho" >By: Bishal Foundation </p> */}
                                                <Link to="" id="linkss" >
                                                    <button className="event-button" >
                                                        read more
                                                    </button>
                                                </Link>   
                                            </div>
                                        </div>           
                            )
                        })
                    }  
            </div>
                <div className="btn">
                <Link to="/all-events">  <button id="btn">View All</button></Link>
                </div>
            </div>
        </div>
    </>
    )
}

export default Posts
