import React from 'react'
import './faq.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Faq() {
    return (
        <div >
             <Header/>
            <Mobilenav/>
            <div className="faq-main pt-4">
            <h3 className="faqss pt-5 pb-5"> Frequently Asked Questions (FAQ) </h3>
            
            <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Q. What is Bishal Foundation?
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            The Bishal Foundation is a non-profit organization working for the prosperity of Nepal. It is a charitable organization of entrepreneurs who stepped forward intending to contribute to the development and prosperity of Nepal through social activities. This foundation which does not discriminate on basis of caste, religion, gender, race, political belief mainly conducts programs in financial development and entrepreneurship development.

            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Q. What is the history of the Bishal Foundation?
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              Nepal is a beautiful country full of natural resources. Though it has immense possibilities in sectors such as agriculture, tourism, and industry, the development of the country has not been as expected. The situation of Education, Health, Entrepreneurship, and Children’s rights is not satisfactory. Developing a positive attitude among the youth has still been a challenging task. The players in Nepal are very hardworking. But due to limited means and resources, they have not been able to utilize their full potential and produce the best results. In this context, a group of successful entrepreneurs from different sectors established the Bishal Organization on 14th Poush 2074 B.S. (December 29, 2017) intending to contribute to the economy of the country. Its main objective is to help the youth in new startup companies, invest in their innovative ideas, and run a multi-purpose company by a single name. While working constantly to accomplish the goals, we focus on the prosperity of the country and for this, different sectors and areas of development should be successful. The Bishal Foundation was established as a non-profit organization to manage social responsibility programs of the corporate organizations and help the government by conducting different social activities. Besides Bishal Organization and its sister companies, the Foundation has been conducting various programs in collaboration and cooperation with corporate houses, government and donor agencies. The sole purpose of these programs is to make people happy by helping, energizing and boosting their confidence. We are constantly working on this project and we shall continue to work.


            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            Q. What is the purpose of a Bishal Foundation?
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            Bishal Foundation is charitable organization of entrepreneurs devoted in sustainable development. Programs based on goal of sustainable development, humanitarian aid, skill development training, public awareness programs and community development are being performed through this foundation.

            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
           Q. Who benefits?
            </button>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse " aria-labelledby="headingFour" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            Marginalized community deprived from main flow of the nation has turned into directly benefited through the projects operated by the Bishal Foundation. Except these, lots of people who are victims of miscellaneous natural disaster and act of god, youths desiring to become entrepreneur, citizens, women, dalit (oppressed group), janajati (ethnic group), deprived community and class without skills are being provided support. Policy maker and government are also being directly/indirectly assisted and co-ordinated through the political dialogue and study, research oriented programs. 

            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
            Q. Who Supports Foundation? 
            </button>
          </h2>
          <div id="collapseFive" className="accordion-collapse collapse " aria-labelledby="headingFive" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            Bishal Foundation is a Non-governmental company registered in Office of Company Registrar as a profit non-distributing company due to which projects shall be operated from financial, technical and physical support of its main commercial company, Bishal Organization and entrepreneurs involved in it as a volunteer. Except this, miscellaneous corporate company, bank, financial institutions shall donate for miscellaneous projects. Projects shall be operated in partnership of governmental bodies, private sector, international donor organizations abiding the law of government. In case of any personal and organizational donations seems to be consistence or beneficial to the goal of the foundation, such offers shall be accepted. 

            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
            Q. How can I get involved in the Bishal Foundation movement?
            </button>
          </h2>
          <div id="collapseSix" className="accordion-collapse collapse " aria-labelledby="headingSix" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            You may become a general member, life-long member in order to participate in the programs organized by Bishal Foundation. There can be the participation of as job holder and internship during the period of offer made by foundation. Similarly, the involvement can be made as volunteer and being jointly ventured in the programs. 
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSeven">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
            Q. Can I volunteer for Foundation?
            </button>
          </h2>
          <div id="collapseSeven" className="accordion-collapse collapse " aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            Of course, you can work as volunteer. The person whoever interested can become a volunteer by clicking this link. 
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingEight">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
            Q. Are you a religious-based organization?
            </button>
          </h2>
          <div id="collapseEight" className="accordion-collapse collapse " aria-labelledby="headingEight" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            This foundation which does not discriminate on basis of caste, religion, gender, race, political belief mainly conducts programs in financial development and entrepreneurship development.

            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingNine">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
            Q. What is the difference between a Bishal foundation and an NGO?
            </button>
          </h2>
          <div id="collapseNine" className="accordion-collapse collapse " aria-labelledby="headingNine" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            There are some differences between Bishal Foundation and Non-government Organization in terms of operational process, membership and registration procedure despite having the same kind of purpose and functional strategy. Bishal Foundation is a company registered in the office of Company Registrar as profit non-distributing company. There shall be Board of Director and this foundation shall be run by the contribution of entrepreneurs involved in this company. The matter relating to operation of the company through internal source shall be the first priority. The company shall have its objective of spending the certain amount of profit accrued from income for the entire welfare of the society under social responsibility. Besides, it can also adopt the aid of government, private sector and donor agency if it is compatible with its rules and objectives. 

            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTen">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
            Q. Does Foundation provide grants?
            </button>
          </h2>
          <div id="collapseTen" className="accordion-collapse collapse " aria-labelledby="headingTen" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            Bishal Foundation does not provide direct grant to any person or organization. It can conduct the programs in joint venture. In case of the submission of an offer by any person or organization throughout Nepal compatible with the objective of Bishal Foundation, the program may take place in joint venture with the financial assistance by taking the consent of the Board of Director. 

            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingEleven">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapsee">
            Q. If I am an international donor, can I still make a donation to Bishal Foundation?
            </button>
          </h2>
          <div id="collapseEleven" className="accordion-collapse collapse " aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            Yes. You can donate as an affiliated international donor. For this, you can contact by clicking in this link. 
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwelve">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="true" aria-controls="collapseTwelve">
            Q. Can I donate clothes, food, or other supplies?
            </button>
          </h2>
          <div id="collapseTwelve" className="accordion-collapse collapse " aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            In case of other general circumstances other than any special situation and incident, the Foundation does not use to accept the clothes, food and other supplies. If it is during the course of the campaigning conducted for the short period, the technical support can be provided as per the demand of the targeted group. 

            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThirteen">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="true" aria-controls="collapseThirteen">
            Q. Where is the Foundation located?
            </button>
          </h2>
          <div id="collapseThirteen" className="accordion-collapse collapse " aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            The head office of Bishal Foundation is located at Kathmandu Metropolitan City, Ward no. 4, Maharajgunj, Kathmandu, Nepal. Its operating areas are throughout the country Nepal, so the project offices are in operation in all 7 provinces.

            </div>
          </div>
        </div>
      </div>
        
     

        </div>
        <GoogleMap/>
      <Footer/>
    <Copyright/>
        </div>
    )
}

export default Faq
