import React from 'react';
import './advb.css';
import Header from '../header/Header';
import Footer from '../pages/Footer';
import GoogleMap from '../pages/GoogleMap';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';
import allteam from '../../image/allteam.JPG'
import suman from '../../image/sangam.JPG'
import dipu from '../../image/dipu.JPG'


function Advb() {
    return (

        <div >
         <Header/>
         <Mobilenav/>
         <div className="executive_board">
            <div className="executive_banner">
              <img src={allteam} alt="" />
            </div>
            <div className="team pt-5">
                <h1  id="le">Executive Board</h1>
            </div>
            <div className="executive_teams">
              <div className="teams_member">
                <div className="teams_card">
                  <div className="img">
                    <img src="./images/team3.jpg" alt="" />
                  </div>
                  <div className="detailsss">
                    <div className="name">Mr. Naresh Prasad Shrestha</div>
                    <div className="job">Chairman</div>
                  </div>
                    <div className="media-icons">
                      <a href="#"><i className="fab fa-facebook-f" /></a>
                      <a href="#"><i className="fab fa-twitter" /></a>
                      <a href="#"><i className="fab fa-instagram" /></a>
                      <a href="#"><i className="fab fa-youtube" /></a>
                    </div>
                </div>
                <div className="teams_card">
                <div className="img">
                <img src="./images/team6.jpg" alt="" />
                </div>
                <div className="detailsss">
                  <div className="name">Mr. Bishal Gaire</div>
                  <div className="job">Executive Director</div>
                </div>
                    <div className="media-icons">
                      <a href="#"><i className="fab fa-facebook-f" /></a>
                      <a href="#"><i className="fab fa-twitter" /></a>
                      <a href="#"><i className="fab fa-instagram" /></a>
                      <a href="#"><i className="fab fa-youtube" /></a>
                    </div>
                </div>
                <div className="teams_card">
                  <div className="img">
                  <img src="./images/team4.jpg" alt="" />
                  </div>
                  <div className="detailsss">
                    <div className="name">Mr. Tanka Pant</div>
                    <div className="job">Director</div>
                  </div>
                    <div className="media-icons">
                      <a href="#"><i className="fab fa-facebook-f" /></a>
                      <a href="#"><i className="fab fa-twitter" /></a>
                      <a href="#"><i className="fab fa-instagram" /></a>
                      <a href="#"><i className="fab fa-youtube" /></a>
                    </div>
                </div>

              </div>
            </div>
         </div>
            
      <GoogleMap/>
      <Footer/>
      <Copyright/>
        </div>
        
    )
}

export default Advb
