import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './mnav.css'
import { Link } from 'react-router-dom';
import logo from '../../image/bfoundationlogo.svg'

function Mobilenav() {
    return (
        <div >
            <nav className="navbar navbar-expand-lg navbar-light nvs dnos">
                <div className="col-md-1"></div>
                <div className="container-fluid">
                <Link to="/" className="navbar-brand" ><img src={logo} alt="" height="59px" width="155px"/></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                    <div className="collapse navbar-collapse  coll" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                            <li className="nav-item dropdown pnn">
                                <a className="nav-link dropdown-toggle active nldt " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Who We Are
                                </a>
                                <ul className="dropdown-menu dm" aria-labelledby="navbarDropdown">
                                    <li><Link to="/aboutus" className="dropdown-item" >About Us</Link></li>
                                    <li><Link to="/executiveboard" className="dropdown-item" >Executive Board </Link></li>
                                    <li><Link to="/avisorboard" className="dropdown-item" >Advisory Board</Link></li>
                                    <li><Link to="/secreatariat" className="dropdown-item" >Secreatariat</Link></li>
                                    <li><Link to="/accountandtransparency" className="dropdown-item bn" >Transparency & <br /> Accountability</Link></li>
                                    <li  className="bn"><Link to="/faq" className="dropdown-item" >FAQ</Link></li>
                                </ul>
                            </li>
                        </ul>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown pnn">
                                <a className="nav-link dropdown-toggle active nldt" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    What We do
                                </a>
                                <ul className="dropdown-menu dm" aria-labelledby="navbarDropdown">
                                    <li><Link to="/sdgs" className="dropdown-item  ">SDGS(Sustainable <br /> Development Goals)</Link></li>
                                    <li><Link to="/communitybasedprogram" className="dropdown-item bl" >Community Based <br /> Programs</Link></li>
                                        <li><Link to="/communitybasedprogram" className="dropdown-item">Humanitarian Aid</Link></li>
                                        <li><Link to="/communitybasedprogram" className="dropdown-item" >Skill Training</Link></li>
                                        <li><Link to="/awareness" className="dropdown-item" >Awareness Program</Link></li>
                                        
                                    <li ><Link to="/startupideacallandprogram" className="dropdown-item bl" >Startup Idea Call  <br /> Program</Link></li>
                                    <li  className="bn"><Link to="/researchandpolicydialogue" className="dropdown-item bl">Research and Policy<br /> Dialogue</Link></li>
                                </ul>
                            </li>
                        </ul>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown pnn">
                                <a className="nav-link dropdown-toggle active nldt" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Resource And Media
                                </a>
                                <ul className="dropdown-menu dm" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="#">Media Center</a></li>
                                    <li><Link to="/pressrelease" className="dropdown-item" >Press Release </Link></li>
                                    <li><a className="dropdown-item" href="#successful">Successful Stories </a></li>
                                    <li><Link to="/allnews" className="dropdown-item" href="#newscov">News Coverage</Link></li>
                                    <li><Link to="/allvideos" className="dropdown-item" href="#">Speech/videos</Link></li>
                                    <li><a className="dropdown-item" href="#">Annual REports</a></li>

                                </ul>
                            </li>
                        </ul>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown pnn">
                                <a className="nav-link dropdown-toggle active nldt" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Get Involved
                                </a>
                                <ul className="dropdown-menu  dm" aria-labelledby="navbarDropdown">
                                    <li><Link to="/internshipprogram"  className="dropdown-item" >Internship</Link></li>
                                    <li><Link to="/presentvolunteers" className="dropdown-item">Volunteering </Link></li>
                                    <li><Link  to="/allpartners" className="dropdown-item" href="#">Partnership</Link></li>
                                    <li><Link to="/career" className="dropdown-item" >Carrer</Link></li>
                                    <li  className="bn"><Link to="/donate" className="dropdown-item" href="#">Donate</Link></li>
                                </ul>
                            </li>
                        </ul>
                            {/* <li className="fw" >
                                <select className="selt">
                                    <option value="">English</option>
                                    <option value="">Nepali</option> 
                                </select>
                            </li> */}
                        <li className="fws">
                            <div className="frs">
                                <input type="text" id="ttx" placeholder="  serch..."/>
                               
                                <button id="sech" style={{marginTop:'2px'}}>
                                    <i class="fas fa-search" id="ch"></i>
                                </button>
                               
                            </div>
                        </li>
                        
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Mobilenav
