import React from 'react'
import './health.css'
import './skil.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Skill() {
    return (
        <div>
             <Header/>
            <Mobilenav/>
            <div className="sdgss">
        <div className="art">
          <div className="col-md-12 contentss">
            <div className=" dfc">
              <p className="ssspw">Skill Training</p>
              <p className="textss">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
               
              </p>
            </div>
            <div className="image">
              <img src="./images/skill1.jpg" alt="" />
            </div>
          </div>
        </div>
        <div className="gallerys">
            <p><span>Some Photos Of Skill Training</span></p>
            <div className="imagess">
              <div className="img1">
                  <a href=""><img src="./images/skill2.jpg" alt="" /></a>
              </div>
              <div className="img1">
                  <a href=""><img src="./images/skill3.jpg" alt="" /></a>
              </div>
              <div className="img1">
                  <a href=""><img src="./images/skill4.jpg" alt="" /></a>
              </div>
            </div>
        </div>
      </div>
      <GoogleMap/>
      <Footer/>
    <Copyright/> 
        </div>
    )
}

export default Skill
