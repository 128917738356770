import React,{useState,useEffect} from 'react'
import axios from 'axios'
function PressAPI() {
    const [press,setPress]=useState([])


    const getPress = async ()=>{
        const res = await axios.get(`/api/showepress`)
        setPress(res.data)
    }
    useEffect(()=>{
        getPress()
    },[])
    return{
        press: [press,setPress],
        
    }
}

export default PressAPI
