import React,{useContext} from 'react';
import './pressrelease.css';
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';
import { GlobalState } from '../../GlobalState';

function Pressrelease() {
    const state = useContext(GlobalState)
    const [press]=state.pressAPI.press

    return (
        <div >
            <Header/>
            <Mobilenav/>
            <div className="press pt-4">
                <h3 id="pl">Press Release</h3>
                <div className="tablesss ">
                <table className="tablessss">
                        <thead>
                        <tr>
                            <th scope="col"style={{width:'50px'}}>S.N</th>
                            <th scope="col"style={{width:'180px'}}>Press Realese Id</th>
                            <th scope="col" style={{width:'210px'}} >Date</th>
                            <th scope="col" style={{width:'530px'}} >Title</th>
                            <th scope="col" style={{width:'330px'}}>Pdf File</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            press.map((presss,index)=>(
                                <tr key={presss._id}>
                                    <td>{index+1}</td>
                                    <td> {presss.prid}</td>
                                    <td> {presss.date}</td>
                                    <td>{presss.title}</td>
                                    <td ><a href = {presss.presspdf} target = "_blank">Click to Download Pdf</a></td>
                                </tr>
                                ))
                            } 
                        </tbody>
                    </table>
                </div>
            </div>
            <GoogleMap/>
      <Footer/>
    <Copyright/>
        </div>
    )
}

export default Pressrelease
