import React from 'react'
import './load.css'

function Load() {
    return (
        <div>
            <div className="loading">
                <img src="./images/bishallogo.png" alt="" />
                <div className="welcome pt-5">
                    <h2>Welcome To <span>Bishal Foundation</span></h2>
                </div>
            </div>
        </div>
    )
}

export default Load
