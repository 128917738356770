import React, {createContext,useState,useEffect} from 'react' 
import EventAPI from './components/api/EventApi'
import GalleryApi from './components/api/GalleryApi'
import StoryAPI from './components/api/StoryApi'
import axios from 'axios'
import PartnerAPI from './components/api/PartnerApi'
import CategoriesAPI from './components/api/CategoryApi'
import ContactAPI from './components/api/ContactApi'
import PressAPI from './components/api/PressApi'



export const GlobalState = createContext()

export const DataProvider = ({children})=>{

    const [partners,setPartner]=useState([])
    const [callback,setCallback] = useState(false)
    const [category,setCategory] = useState('')
    const [result ,setResult] = useState(0)
    
    const getPartner = async ()=>{
        const res = await axios.get(`/api/showPartner?${category}`)
        setPartner(res.data.partners)
        setResult(res.data.result)
    }
    useEffect(()=>{
        getPartner()
    },[callback,category])
    
 const state={
        eventsAPI : EventAPI(),
        storyAPI : StoryAPI(),
        imageAPI : GalleryApi(),
        partnerAPI : PartnerAPI(),
        categoriesAPI : CategoriesAPI(),
        contactsAPI : ContactAPI(),
        pressAPI : PressAPI(),
    }
    return(
        <GlobalState.Provider value={state}>
            {children}
        </GlobalState.Provider>
    )
}