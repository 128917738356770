import React from 'react'
import './career.css'
import './sdgs.css';
import './internship.css';
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Career() {
    return (
        <div>
            <Header/>
            <Mobilenav/>
            <div className="careerss">
                <img src="./images/bishal5.jpg" alt="" />
                <div className="carrs">
                    <p><span>Careers at Bishal Foundation</span></p>
                    <div className="ctt">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                             Amet molestias perferendis corporis alias. Soluta
                              officiis eveniet est expedita facilis, sed ducimus deserunt sit, cum, 
                            et doloribus cupiditate placeat deleniti! Ducimus!</p>
                    </div>
                </div>
            </div>
            <div className="sdgss" id="career">
        <div className="art">
          <div className="col-md-12 contentss">
            <div className=" dfc">
              <p className="pssws">Career in Bishal Foundation</p>
              <p className="textss">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
               
              </p>
            </div>
            <div className="image">
            <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fbishalfoundation%2Fvideos%2F193021309222173%2F&show_text=false&width=560&t=0"
             width="350" height="230" 
             scrolling="no" frameborder="0" allowfullscreen="true" 
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
            </div>
          </div>
        </div>
      </div>
      <GoogleMap/>
      <Footer/>
    <Copyright/>  
        </div>
    )
}

export default Career
