import React from 'react'
import './sdgs.css'
import './startupidea.css'
import Header from '../header/Header';
import GoogleMap from '../pages/GoogleMap';
import Footer from '../pages/Footer';
import Copyright from '../pages/Copyright';
import Mobilenav from '../header/Mobilenav';

function Startupida() {
    return (
        <div >
            <Header/>
            <Mobilenav/>
            <div className="community-img">
                    <img src="./images/bishal0.jpg" alt="" />
                </div>
            <div className="sdgss">
        <div className="art">
          <div className="col-md-12 contentss">
            <div className=" dfc">
              <p className="sspws">Startup Idea Call Program</p>
              <p className="textss">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
                Cupiditate, sed ad natus explicabo dolores dolorem aliquid?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa similique ad totam eum! Odit
                laudantium quia quasi repellat eveniet perferendis. Quam, cupiditate. 
               
              </p>
            </div>
            <div className="image">  
                <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fbishalfoundation%2Fvideos%2F193021309222173%2F&show_text=0&width=560" width="350" height="230" style={{border:'none',overflow:'hidden'}}
              scrolling="no" frameborder="0" allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
            </div>
          </div>
        </div>
      </div>
      <GoogleMap/>
      <Footer/>
    <Copyright/>
        </div>
    )
}

export default Startupida
