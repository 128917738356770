import React,{useState,useEffect} from 'react'
import axios from 'axios'
function StoryAPI() {
    const [story,setStory]=useState([])


    const getStory = async ()=>{
        const res = await axios.get(`/api/showstory`)
        setStory(res.data)
    }
    useEffect(()=>{
        getStory()
    },[])
    return{
        story: [story,setStory],
        
    }
}

export default StoryAPI
