import React,{useEffect} from 'react'
import './MeetOurTeam.css'
import { Link } from 'react-router-dom';
import team1 from '../../image/sangam.JPG'
import team2 from '../../image/dipu.JPG'
import Aos from 'aos'
import 'aos/dist/aos.css'
function MeetOurTeam() {

  useEffect(()=>{
    Aos.init({duration:2000});
},[])

    return (
        <div className="pt-5">
            <div className="team pt-3">
                <h1  id="le">Executive Board</h1>
            </div>
            <div className="MeetOurTeam pt-4" data-aos="flip-right" data-aos="fade-right">
        <div className="container ctrr">
        <input type="radio" name="dot" id="one" />
        <input type="radio" name="dot" id="two" />
        <div className="main-card">
          <div className="cards">
            <div className="card">
              <div className="content">
                <div className="img">
                  <img src="./images/team3.jpg" alt="" />
                </div>
                <div className="detailsss">
                  <div className="name">Mr. Naresh Prasad Shrestha</div>
                  <div className="job">Chairman</div>
                </div>
                <div className="media-icons">
                  <a href="#"><i className="fab fa-facebook-f" /></a>
                  <a href="#"><i className="fab fa-twitter" /></a>
                  <a href="#"><i className="fab fa-instagram" /></a>
                  <a href="#"><i className="fab fa-youtube" /></a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="content">
                <div className="img">
                <img src="./images/team6.jpg" alt="" />
                </div>
                <div className="detailsss">
                  <div className="name">Mr. Bishal Gaire</div>
                  <div className="job">Executive Director</div>
                </div>
                <div className="media-icons">
                  <a href="#"><i className="fab fa-facebook-f" /></a>
                  <a href="#"><i className="fab fa-twitter" /></a>
                  <a href="#"><i className="fab fa-instagram" /></a>
                  <a href="#"><i className="fab fa-youtube" /></a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="content">
                <div className="img">
                <img src="./images/team4.jpg" alt="" />
                </div>
                <div className="detailsss">
                  <div className="name">Mr. Tanka Pant</div>
                  <div className="job">Director</div>
                </div>
                <div className="media-icons">
                  <a href="#"><i className="fab fa-facebook-f" /></a>
                  <a href="#"><i className="fab fa-twitter" /></a>
                  <a href="#"><i className="fab fa-instagram" /></a>
                  <a href="#"><i className="fab fa-youtube" /></a>
                </div>
              </div>
            </div>
          </div>
        
        </div>
        <div className="button">
          <label htmlFor="one" className=" active one" />
          <label htmlFor="two" className="two" />
        </div>
      </div>     
        </div>
        </div>
    )
}

export default MeetOurTeam
