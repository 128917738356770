import React,{useEffect} from 'react'
import './Datarepresentation.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
function Datarepresentation() {
    
  useEffect(()=>{
    Aos.init({duration:2000});
},[])
    return (
        <div >
            <div className="data-representation pt-5" >
             <div className="data-name pt-3">
                    <h1 id="le"> Our Journey</h1>
                </div>
            <div className="data_back_image" >
                <div className="data-container pt-5" data-aos="fade-up" >
                    <div className="col-lg-3 col-12 text-center col-sm-5">
                        <h2 className="data-number"> 21 </h2>
                        <h5>Successful Project</h5>
                    </div>
                    <div className="col-lg-3 col-12 text-center col-sm-5">
                        <h2 className="data-number"> <span id="datak">125K<sup>+</sup></span> </h2>
                        <h5>Public Reached</h5>
                    </div>
                    <div className="col-lg-3 col-12 text-center col-sm-5">
                        <h2 className="data-number"> 57 </h2>
                        <h5>Partnership</h5>
                    </div>
                    <div className="col-lg-3 col-12 text-center col-sm-5">
                        <h2 className="data-number"> 700 </h2>
                        <h5> BF Family</h5>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
    )
}

export default Datarepresentation
