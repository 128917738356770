import React,{useContext,useState,useRef,useEffect} from 'react'
import { GlobalState } from '../../GlobalState'
import './SuccessfullStories.css'
import Aos from 'aos'
import 'aos/dist/aos.css'


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {
    Parallax,Pagination,Navigation, Autoplay,
  } from 'swiper/core';
  
  // install Swiper modules
  SwiperCore.use([Parallax,Pagination,Navigation, Autoplay]);
  
  

function SuccessfullStories() {
    const state = useContext(GlobalState)
    const [stories]=state.storyAPI.story
    const [visible, setVisible] = useState(5)

    useEffect(()=>{
        Aos.init({duration:2000});
    },[])
    return (
        <div className="pbs pt-4" data-aos="fade-up">
             <h1 id="le" className="pb-4">Successful Stories</h1>
            <div id="successful" className>
              
                <Swiper style={{'--swiper-navigation-color': '#000','--swiper-pagination-color': '#000'}}
                            speed={600} parallax={true}
                            Autoplay={5000}
                            
                                pagination={{
                    "clickable": true
                    }} navigation={true} className="mySwiper">
                                            
                            {
                        stories.slice(0,visible).map(story=>{
                            return ( 
                                <SwiperSlide key={story._id}>
                                        <div id="flexs">
                                        <div className="col-md-1" ></div>
                                        <div className="col-md-6 col-sm-12 ci">
                                            <h3><span>{story.title}</span></h3>
                                            <p>{story.description}</p>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-3 col-sm-12 ssi" >
                                            <img src={'/'+story.image} className="d-block" alt="..." />
                                        </div>
                                    <div className="col-md-1"></div>
                                    </div>
                            </SwiperSlide>
                                    
                               
                            )
                        })
                    }   
                       </Swiper> 
                    </div>
               
               
        </div>
    )
}

export default SuccessfullStories
